.custom-modal {

  .modal-body {
    padding: 1rem 2rem;
  }

  .action-modal {
    max-width: 340px;
  }

  .modal-content {
    border-radius: 1rem;
  }

  .modal-header-with-logo {
    width: 100%;
    position: relative;

    .modal-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal-close-button {
      position: absolute;
      right: -25px;
      top: -15px;

      button {
        font-size: 2rem;
        font-weight: normal;
      }
    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-footer {
    border-top: 0;
  }

  .modal-body-with-content {
    p {
      margin: 0;
      font-size: .8rem;
    }

    h3 {
      margin: 1.2rem 0 1.5rem;
    }

    input, select {
      border-radius: 1.4rem;
      font-size: .8rem;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);

      &:hover, &:focus {
        border-radius: 1rem;
        border: 1px solid lightblue;
        box-shadow: 0 0 11px lightblue;
      }
    }

    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      position: relative;
    }

    .btn-round-primary {
      @extend .btn-round-primary;
      text-transform: none;
      border-radius: 1.4rem;
      width: 100%;
    }

    .remember-me-and-forgot {
      margin: 1.5rem 0 1rem;
      display: flex;
      justify-content: space-between;
      font-size: .8rem;

      * {
        color: $font-color;
      }

      .form-group {
        margin: 0;
      }

      label {
        margin: 0;
      }
    }

    .pink {
      color: #f07b73;
    }
  }

  @include for-extra-small-devices {
    .modal-dialog {
      max-width: 100%;
    }
  }
}
