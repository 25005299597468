#blogs {

  .blog-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .blogs-container {
    width: 75%;
    padding-top: 2rem;

    .blog-item {
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      .image {
        flex-basis: 35%;

        img {
          border-radius: 0;
          width: 100%;
          height: auto;
        }
      }

      .blog-details {
        flex-basis: 60%;
        padding: .5rem 0;

        h6 {
          margin: 0 0 .7rem;
          color: #222;
          line-height: 1;
          font-size: 1.4rem;
        }

        .details {
          font-size: .9rem;
        }

        .post-date {
          font-size: .75rem;
          margin: 0 0 0.3rem 0;

          p {
            margin: 0;
            padding: 0 0 0.2rem;
          }

          .divider {
            width: 30%;
            height: 2px;
            background-color: #fdc800;
          }
        }

        .details-button {
          text-align: right;

          .read-more {
            text-align: right;
            font-size: .9rem;
            font-style: italic;
            color: #000;
            text-decoration: underline;
          }
        }
      }

      .main-details {
      }

      /*& > .details-button {
        display: none;
      }*/
    }
  }

  .blog-sidebar {
    overflow: hidden;
    width: 20%;
    padding-top: 2rem;

    .main-heading-style {
      text-transform: uppercase;
      font-size: 1rem;
      margin-bottom: 1.5rem;

      &::before {
        bottom: 4px;
        width: 80px;
      }
    }

    .search-container {
      position: relative;
      max-width: 180px;
      margin-bottom: 3rem;

      .search-input {
        border-radius: 15px;
        padding-right: 43px;
        border: 2px solid $secondary-color;

        * {
          transition: all 300ms ease-in-out;
        }

        &:hover {
          border-color: $secondary-color;
          box-shadow: none;
        }

        &:focus {
          border-color: $secondary-color;
          box-shadow: none;
        }
      }

      .search-button {
        position: absolute;
        top: 0;
        right: 0;
        color: $primary-color;
      }
    }

    .categories-container {
      margin-bottom: 3rem;

      .categories-list {
        list-style: none;
        padding: 0;

        .category-item {
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $light-black;
          font-size: .8rem;
          padding: .7rem;
          border-bottom: 1px solid lighten($grey, 15%);

          &:first-of-type {
            margin-top: 0;
          }

          * {
            margin: 0;
          }
        }
      }
    }
  }
}

.blog-category-bar {
  display: none;
}

@include for-extra-small-devices {

  .blog-category-bar {
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid $light-grey;
  }

  .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category-aria {
      display: flex;
      flex-direction: column;

      a {
        color: $font-color;
        text-decoration: underline;
        text-transform: uppercase;
      }

      p {
        margin: 0;
        font-size: .8rem;
        padding-left: .4rem;

        span {
          padding-left: .4rem;
        }
      }
    }

    .search-aria {
      //display: none;
      flex-grow: 2;
      margin-left: .3rem;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;

      .search-icon {
        color: $white;
        background-color: $secondary-color;
        height: calc(1.5em + .75rem + 2px);
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 99;
        cursor: pointer;
      }

      form {
        z-index: 9;
        transform: translateX(200%);
        opacity: 0;
        transition: opacity 150ms ease-in-out 150ms, transform 300ms ease-in-out;

        &.show {
          opacity: 1;
          transform: translateX(15%);
        }

        input {
          width: 80%;
          border-radius: 15px;
          border: 2px solid $secondary-color;

          &:hover {
            border-color: $secondary-color;
            box-shadow: none;
          }

          &:focus {
            border-color: $secondary-color;
            box-shadow: none;
          }
        }
      }

      .form-group {
        margin: 0;
      }
    }
  }

  .categories-container {
    margin-bottom: 1rem;

    .categories-list {
      list-style: none;
      padding: 0;

      .category-item {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $light-black;
        font-size: .8rem;
        padding: .7rem;
        border-bottom: 1px solid lighten($grey, 15%);

        &:first-of-type {
          margin-top: 0;
        }

        * {
          margin: 0;
        }
      }
    }
  }

  #blogs {
    .blog-flex {
      flex-direction: row-reverse;
    }

    .blogs-container {
      width: 100%;
      padding: 0;

      .blog-item {
        flex-direction: column;
        margin: 0;
        border-bottom: 4px solid $light-grey;
        padding: 2rem 0;

        .image {
          flex-basis: 35%;
          margin-bottom: 1rem;
        }

        .blog-details {
          flex-basis: 62%;

          h6 {
            margin: 0;
            font-weight: bold;
            font-size: 1.2rem;
          }

          .post-date {
            .divider {
              width: 60%;
            }
          }

          .details {
            font-size: .8rem;
            line-height: 1.2;
            margin-bottom: .5rem;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .blog-sidebar {
      display: none;
    }
  }
}

@include for-small-devices {

  .blog-category-bar {
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid $light-grey;
  }

  .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category-aria {
      display: flex;
      flex-direction: column;

      a {
        color: $font-color;
        text-decoration: underline;
        text-transform: uppercase;
      }

      p {
        margin: 0;
        font-size: .8rem;
        padding-left: .4rem;

        span {
          padding-left: .4rem;
        }
      }
    }

    .search-aria {
      //display: none;
      flex-grow: 2;
      margin-left: .3rem;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;

      .search-icon {
        color: $white;
        background-color: $secondary-color;
        height: calc(1.5em + .75rem + 2px);
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 99;
        cursor: pointer;
      }

      form {
        z-index: 9;
        transform: translateX(200%);
        opacity: 0;
        transition: opacity 150ms ease-in-out 150ms, transform 300ms ease-in-out;

        &.show {
          opacity: 1;
          transform: translateX(5%);
        }

        input {
          width: 90%;
          border-radius: 15px;
          border: 2px solid $secondary-color;

          &:hover {
            border-color: $secondary-color;
            box-shadow: none;
          }

          &:focus {
            border-color: $secondary-color;
            box-shadow: none;
          }
        }
      }

      .form-group {
        margin: 0;
      }
    }
  }

  .categories-container {
    //margin-bottom: 1rem;

    .categories-list {
      list-style: none;
      padding: 0;
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;

      /*& > li {
        flex-basis: 50%;
      }*/

      .category-item {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $light-black;
        font-size: .8rem;
        padding: .7rem;
        border-bottom: 1px solid lighten($grey, 15%);

        &:first-of-type {
          margin-top: 0;
        }

        * {
          margin: 0;
        }
      }
    }
  }

  #blogs {

    .blogs-container {
      width: 100%;
      padding: 0;

      .blog-item {
        margin: 0;
        border-bottom: 4px solid $light-grey;
        padding: 2rem 0;

        .image {
          flex-basis: 35%;
        }

        .blog-details {
          flex-basis: 62%;
          padding: 0;

          h6 {
            margin: 0;
            font-weight: bold;
            font-size: .9rem;
          }

          .post-date {
            .divider {
              width: 60%;
            }
          }

          .details {
            font-size: .8rem;
            line-height: 1.2;
            margin-bottom: .5rem;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .blog-sidebar {
      display: none;
    }
  }

}

@include for-medium-devices {

  #blogs {
    .blogs-container {
      width: 70%;

      .blog-item {

        .blog-details {
          padding: 0;

          h6 {
            margin: 0 0 0.1rem;
            font-size: 1.2rem;
          }

          .post-date {
            margin: 0 0 0.1rem;

            p {
              padding: 0;
            }
          }

          .details {
            font-size: .8rem;
            line-height: 1.3;
          }
        }
      }
    }

    .blog-sidebar {
      width: 25%;
    }
  }
}
