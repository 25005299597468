header {
  background: url("../img/schotest_pattern.svg") repeat 80%;
  background-size: contain;
  height: 95vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  .form-group {
    margin: 0;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 25, 69, .5);
    z-index: -1;
  }

  .location-selector {
    padding: 0 .5rem !important;
    color: $font-color !important;
    border-radius: 15px;
    height: auto !important;
    font-style: italic;
    font-size: .9rem;
  }

  .top-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    //background-color: #00275b;
    background-color: transparent;
    padding: 1rem 1rem .5rem;

    a {
      text-decoration: none;
    }

    .links {
      padding: 0 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      * {
        color: $white;
      }
    }

    .login-link {
      padding-right: 1.5rem;
      border-right: 1px solid $secondary-color;
      margin-right: 1.5rem;
    }
  }

  .search-container {
    width: calc(100% - 30%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    .heading {
      color: $secondary-color;
      font-weight: 700;
      font-size: 2.2rem;
      text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.9);
    }

    .search {
      display: flex;
    }

    .logo {
      width: 60px;
      height: 60px;
      margin-right: 1rem;

      img {
        width: 100%;
      }
    }

    input[name="school_name"] {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0;
      min-width: 250px;
      padding: .5rem;
      font-size: .8rem;
    }

    .btn {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      text-transform: uppercase;
      font-size: .7rem;
      font-weight: bold;
      //padding-left: 1rem !important;
      //padding-right: 1rem !important;
      padding: .45rem 1rem;
    }

  }

  .navigation-bar {
    width: 100%;
    background-color: $primary-color;
    border-top: 1px solid $secondary-color;

    .nav-item {
      border-right: 1px dotted $white;
      padding: 0 .7rem;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;

      .nav-link {
        color: $white;
      }

      &:hover {
        color: $secondary-color !important;
        border-right: 1px dotted $secondary-color;

        .nav-link {
          color: $secondary-color
        }
      }

      &.active {
        color: $secondary-color !important;
        border-right: 1px dotted $secondary-color;

        .nav-link {
          color: $secondary-color
        }
      }
    }

    .dropdown:hover {

      .dropdown-menu {
        display: block;
      }
    }

    .dropdown-menu {
      min-width: 8rem;
      background-color: $primary-color;
      margin-top: 0;
    }

    .dropdown-item {
      background-color: $primary-color;
      color: $white;
      text-transform: none;
      font-size: .8rem;
    }
  }
}

main {
  position: relative;
}

#schools {
  background-color: transparent;
  padding: 3rem 0 1rem;

  .schools-container {
    padding: 3rem 0 4rem;
    overflow: hidden;
  }

  .school-card {
    padding: .6rem;
    width: 80%;
    min-height: 295px;
    max-height: 350px;
    margin: 0 auto 1rem;
    border-radius: 15px;
    //margin-bottom: 1rem;
    -webkit-box-shadow: 0 3px 11px 0 transparentize($pure-black, .6);
    -moz-box-shadow: 0 3px 11px 0 transparentize($pure-black, .6);
    box-shadow: 0 3px 11px 0 transparentize($pure-black, .6);
    transition: all 500ms ease-in-out;

    * {
      transition: all 500ms ease-in-out;
    }

    &:hover {
      transform: scaleX(1.3) scaleY(1.3);

      /*.image {
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
          background: transparentize($primary-color, .3);
        }
      }*/
    }

    a {
      text-decoration: none;
    }

    .image {
      min-height: 120px;
      max-height: 140px;
      border-radius: 15px;
      overflow: hidden;

      img {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .details {
      padding-top: .3rem;
      //height: 55%;

      .name {
        font-weight: bold;
        color: $black;
        text-align: center;
        position: relative;
        padding: 0 0 .5rem;
        margin: 0;
        font-size: .9rem;

        &::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 20%;
          right: 20%;
          height: 2px;
          background-color: $secondary-color;
        }
      }

      .type {
        font-size: .7rem;
        font-weight: 300;
        padding: .3rem 0 .4rem;
        margin: 0;
        color: $black;
        text-align: center;
      }

      .review-container {
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: .3rem;

        * {
          flex-basis: 50%;
          font-size: .8rem;
          color: $black;
        }

        .star-rating {
          text-align: center;

          i {
            font-size: .7rem;
          }
        }

        .total-review {
          border-right: 1px solid $light-black;
          text-align: center;

          span {
            //font-size: 1rem;
          }

          sup {
            font-size: 65%;
          }
        }

        /*&:first-child {
          padding-right: 1rem;
        }

        &:last-child {
          padding-left: 1rem;
        }*/
      }

      .basic-details {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        * {
          font-size: .7rem;
          color: $black;
        }

        .description {
          display: flex;
          flex-direction: column;
          flex-basis: 80%;
          margin: 0 auto;
          color: $black;

          .content-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            sup {
              font-size: 70%;
            }
          }
        }

        .compare {
          flex-basis: 30%;
          text-align: right;

          .compare-btn {
            display: inline-block;
            font-weight: 400;
            color: #212529;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: $secondary-color;
            border: 1px solid transparent;
            padding: .1rem .3rem;
            font-size: .7rem;
            line-height: 1.5;
            border-radius: 1rem;
          }
        }
      }
    }
  }

  @include for-extra-small-devices {
    padding-top: 1rem;

    .main-heading-style {
      font-size: 1.5rem;
    }

    .schools-container {
      padding: 0;
    }

    .school-card {
      min-height: auto;
      max-height: initial;
    }
  }
}

#gallery {
  background: url("../img/gallery-bg-image.png") $secondary-color no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 4rem 0;

  .underline-heading {
    color: $white;
    font-size: 4.5rem;
    font-weight: bold;
    text-transform: none;
    position: relative;
    text-align: center;
    text-shadow: 2px 2px 10px transparentize($black, .7);

    &::before {
      position: absolute;
      content: "";
      height: 4px;
      bottom: 10px;
      left: 25%;
      right: 25%;
      background-color: $primary-color;
    }
  }

  .gallery-grid {
    display: grid;
    grid-template-areas: "main main secondary secondary secondary" "main main secondary secondary secondary";
    grid-gap: .3rem;
    border: 3px solid $white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: $white;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    .main-grid {
      //width: 35%;
      grid-area: main;
      display: grid;
      grid-gap: .3rem;
      //grid-template-rows: repeat(3, 1fr);
      grid-template-rows: repeat(3, 102px);
      grid-template-columns: repeat(2, 1fr);
      overflow: hidden;

      .big-image {
        overflow: hidden;
        grid-column: 1/3;
        grid-row: 1/3;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .small-image {
        overflow: hidden;
      }
    }

    .secondary-grid {
      //width: 65%;
      grid-area: secondary;
      display: grid;
      grid-gap: .3rem;
      grid-template-rows: repeat(3, 102px);
      overflow: hidden;

      .image-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: .3rem;
        overflow: hidden;
      }

      .small-image {
        overflow: hidden;
      }
    }
  }

  /*.gallery-grid {
    border: 3px solid $white;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    * {
      padding: 0;
      margin: 0;
    }

    img {
      display: block;
      margin: 0;
      padding: 0;
    }

    .main-grid {
      flex-basis: 40%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      //border-right: 3px solid $white;

      .big-image {
        flex-basis: 100%;
        //border-bottom: 3px solid $white;

        img {
          width: 100%;
        }
      }

      .small-image {
        flex-basis: 50%;
        //border-right: 3px solid $white;

        &:last-child {
          border: 0;
        }

        img {
          width: 100%;
        }
      }
    }

    .secondary-grid {
      flex-basis: 60%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .image-row {
        //border-bottom: 3px solid $white;
        display: flex;
        flex-wrap: wrap;

        &:last-child {
          border-bottom: 0;
        }
      }

      .small-image {
        flex-basis: 33%;
        //border-right: 3px solid $white;

        &:last-child {
          border-right: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }*/

  @media screen and (min-width: 768px) and (max-width: 1023px) {

    .underline-heading {
      font-size: 3rem;
      margin-bottom: 1rem;

      &::before {
        left: 34%;
        right: 35%;
      }
    }
  }
}

#content {
  overflow: hidden;

  .main-heading-style {
    font-size: 2rem;
    margin-bottom: 2rem;

    &::before {
      width: 100px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: lighten($grey, 10%);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $secondary-color;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: darken($secondary-color, 20%);
  }

  .events-container {
    padding: 0;
    overflow: hidden;

    /*.main-heading-style {
      @extend .main-heading-style;
      font-size: 2rem;
      margin-bottom: 2rem;

      &::before {
        width: 40%;
      }
    }*/
  }

  .events {
    background-color: $white;
    padding: 0 .5rem 0 0;
    width: 90%;
    height: 400px;
    overflow: auto;
    margin-bottom: 1rem;

    .event {
      margin-bottom: 1rem;
      border: 1px solid lighten($grey, 15%);
      padding: 0;
      background-color: $white;
      color: $font-color;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .event-image {
        flex-basis: calc(30% - (.7rem / 2));
        width: 100%;
        padding: .4rem;

        img {
          width: 100%;
        }
      }

      .event-details {
        flex-basis: calc(70% - (.7rem / 2));
        padding: .4rem 0 0;

        .date-details {
          margin-bottom: .7rem;
          font-size: .7rem;

          i {
            color: var(--danger);
            margin-right: .3rem;
          }
        }

        p {
          font-size: .666rem;
          margin: 0;
        }
      }

      h6, .heading {
        font-size: .8rem;
        font-weight: bold;
        color: $black;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .more-button {
    margin-top: 1rem;
    @extend .btn-custom-secondary;
    color: $primary-color-shade;
    border-radius: 20px;
    font-weight: bold;
    font-size: .8rem;
    padding: .2rem .4rem;

    &:hover {
      background-color: $white;
      border-color: $secondary-color;
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
    }
  }

  .upcoming-events-container {
    overflow: hidden;

    .upcoming-events {
      height: 400px;
      overflow: auto;
      margin-bottom: 1rem;
    }

    .main-heading-style {
      //@extend .main-heading-style;
      //font-size: 2rem;
      //margin-bottom: 2rem;

      &::before {
        //width: 30%;
        background-color: $secondary-color;
      }
    }

    .upcoming-events {
      padding: 0;

      .event {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2rem;

        .image-details {
          flex-basis: 45%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }

          .time-details {
            line-height: 1;
            padding-right: .7rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $secondary-color;
          }

          .date-details {
            .date {
              color: $secondary-color;
              font-weight: bold;
              font-size: 2.5rem;
            }

            .month {
              color: $black;
              text-transform: uppercase;
              font-size: 1.2rem;
              font-weight: bold;
            }
          }

          .time {
            font-size: .7rem;
            color: $black;
            text-transform: uppercase;
          }
        }

        .event-details {
          padding-left: .7rem;
          flex-basis: calc(55% - .7rem);
          font-size: .8rem;

          h6, .heading {
            color: $primary-color;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: .1rem;
          }

          p.details {
            font-size: .7rem;
            color: $black;
            margin: 0 0 .4rem;
          }

          .address {
            font-size: .7rem;
            color: $primary-color;
            font-weight: bold;
            margin: 0;
          }
        }
      }
    }
  }

  .blogs-container {
    margin: 4rem 0 2rem;

    .main-heading-style {
      text-transform: none;
      margin-bottom: 2rem;

      &::before {
        width: 100%;
      }
    }

    .blog-item {
      height: 155px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .5rem;
      border: 1px solid lighten($grey, 15%);
      -webkit-box-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1);
      -moz-box-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1);
      box-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1);

      .image {
        flex-basis: 40%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .details {
        flex-basis: 55%;
        color: $black;

        .title {
          margin: 0 0 .2rem;
          color: $black;
          //width: 85%;
          line-height: 1;
          font-size: 1.2rem;
        }

        .post-date {
          font-size: .75rem;
          margin: 0 0 0.3rem 0;

          p {
            margin: 0;
            padding: 0 0 0.2rem;
          }

          .divider {
            width: 70%;
            height: 2px;
            background-color: $secondary-color;
          }
        }

        .description {
          font-size: .666rem;
          margin: 0;
        }

        .read-more {
          //display: block;
          text-align: right;
          font-size: .75rem;
          font-style: italic;
          color: $pure-black;
          text-decoration: underline;
        }
      }
    }
  }

  @include for-extra-small-devices {
    padding: 1rem 0;

    .events-container {
      margin-bottom: 2rem;
    }

    .events {
      width: 100%;
    }

    .upcoming-events-container {
      //margin-bottom: 2rem;
    }

    .blogs-container {
      .blog-item {
        height: auto;
      }
    }
  }

  @include for-small-devices {

    .main-heading-style {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .events {
      width: 100%;
    }

    .upcoming-events-container {
      margin-top: 2rem;
      overflow: hidden;
    }

    .blogs-container {
      margin: 2rem 0 0;

      .blog-item {
        height: auto;
        margin-bottom: 1rem;
      }
    }

  }

  @include for-medium-devices {

    .main-heading-style {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .events-container {
      overflow: hidden;
    }

    .upcoming-events-container {
      //margin-top: 2rem;
      overflow: hidden;
    }

    .blogs-container {
      margin: 2rem 0 0;

      .blog-item {
        height: auto;
        margin-bottom: 1rem;
      }
    }
  }
}

#counter {
  background: url("../img/counter-bg.png") no-repeat center;

  * {
    color: $white;
  }

  .counting-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h4 {
      width: auto;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .counting {
    font-size: 3rem;
    border-bottom: 5px solid $secondary-color;
  }

  @include for-small-devices {
    padding: 1rem 0;
    background-size: cover;

    .counting-item {
      h4 {
        text-transform: uppercase;
        font-size: 1.3rem;
      }
    }

    .counting {
      font-size: 2rem;
      border-bottom: 5px solid $secondary-color;
    }

    .counting-item {
      flex-direction: column;
      margin-bottom: 2rem;
    }
  }

  @include for-medium-devices {
    .counting-item {
      h4 {
        font-size: 1rem;
        margin-right: 1rem;
      }
    }
  }

  @include for-large-devices {
    .counting-item {
      h4 {
        font-size: 1.2rem;
        margin-right: 1rem;
      }
    }
  }
}

#founders-pen {

  .founder-image {
    padding: .6rem;
    -webkit-box-shadow: 0 5px 20px 2px transparentize($pure-black, .8);
    -moz-box-shadow: 0 5px 20px 2px transparentize($pure-black, .8);
    box-shadow: 0 5px 20px 2px transparentize($pure-black, .8);

    img {
      width: 100%;
    }
  }

  .desc {
    padding-left: 2rem;
    overflow: hidden;
  }

  .main-heading-style {
    font-size: 1.3rem;
    color: $primary-color;
    text-transform: none;
    margin-bottom: 1rem;
  }

  .details {
    width: 80%;
  }

  .description {
    padding: 0 1rem 0 .7rem;
    font-size: .8rem;
    margin: 0;
    color: $black;
    position: relative;
  }

  .author {
    width: 92%;
    font-size: 1.2rem;
    font-style: italic;
    color: $black;
    margin: 0;
    text-align: right;
  }

  @include for-small-devices {
    // nothing special here right now
    .desc {
      margin-top: 1rem;
    }
  }
}

#testimonials {
  background: url("../img/testimonials-bg.png") no-repeat;

  .underline-heading-style {
    color: $font-color;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: .5rem;
    text-transform: uppercase;
    //text-align: left;
    text-shadow: none;

    &::before {
      height: 3px;
      background-color: $secondary-color;
    }
  }

  .testimonials-container {
    overflow: hidden;
    width: 100%;

    .placeholder-container {
      width: 100%;
    }
  }

  .rating-card {
    //flex-basis: 47%;
    //width: calc(50% - 2rem);
    margin: 0 .5rem 0 0;
    padding: .8rem;
    -webkit-box-shadow: 2px 1px 6px 0 transparentize($pure-black, .8);
    -moz-box-shadow: 2px 1px 6px 0 transparentize($pure-black, .8);
    box-shadow: 2px 1px 6px 0 transparentize($pure-black, .8);
  }

  .basic-details {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      margin: 0;
    }

    .details {
      width: calc(100% - 75px);
      padding: 0 .5rem;
    }

    .user-name {
      margin-top: .5rem;
    }

    .name {
      font-weight: bold;
      font-size: .9rem;
      color: $black;
    }

    .education {
      font-size: .7rem;
      color: $black;
    }

    .profile-image {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      border: 1px solid $grey;
      overflow: hidden;

      img {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    .rating {
      text-align: right;

      i {
        font-size: .7rem;
      }

      p {
        font-size: .7rem;
        margin: 0;
        color: $black;
      }
    }
  }

  .description {
    font-size: .7rem;
    text-align: justify;
    margin: 1rem 0 0;
  }

  .add-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 0;
  }

  .add-testimonial {
    text-align: right;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid $grey;
    color: $grey;
    width: 30px;
    height: 30px;
    -webkit-box-shadow: 2px 1px 10px 0 transparentize($pure-black, .8);
    -moz-box-shadow: 2px 1px 10px 0 transparentize($pure-black, .8);
    box-shadow: 2px 1px 10px 0 transparentize($pure-black, .8);
  }

  @media screen and (min-width: 481px) and (max-width: 767px) {
    .underline-heading-style {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .basic-details {
      .profile-image {
        width: 50px;
        height: 50px;
      }

      .details {
        width: calc(100% - 50px);
      }
    }

    .description {
      margin-top: 1rem;
    }

    .star-rating {
      i {
        font-size: .6rem;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .star-rating {
      i {
        font-size: .48rem;
      }
    }
  }
}

#bde-overview {
  background-color: $secondary-color;
  color: $primary-color;
  padding: 1rem 0;

  .heading {
    text-align: center;
    font-weight: bold;
    font-size: 2.3rem;
  }

  .bde-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .bde-item {
      flex-basis: 14%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .profile-image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .profile-details {
        display: flex;
        flex-direction: column;
        margin-top: .4rem;

        .name {
          font-weight: bold;
          text-align: center;
          margin: 0;
          padding: 0;
          color: $black;
        }

        .location {
          text-align: center;
          margin: 0;
          padding: 0;
          color: $black;
        }
      }
    }
  }

  @include for-small-devices {
    .heading {
      font-size: 2rem;
    }

    .bde-container {
      overflow: hidden;
      flex-wrap: wrap;

      .bde-item {
        .profile-image {
          width: 80px;
          height: 80px;
        }
      }
    }
  }

  @include for-medium-devices {

    .bde-container {
      overflow: hidden;
      flex-wrap: wrap;
    }
  }
}

#associates {
  position: relative;
  border-bottom: none;

  p {
    font-size: .8rem;
  }

  .associates-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .associates-logo {
      width: calc(100% / 4 - 1rem);
      background-color: #ddd;
      margin: 0 .5rem 1rem;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include for-small-devices {
    p {
      font-size: .8rem;
      text-align: justify;
    }
  }

  @include for-medium-devices {
    .main-heading-style {
      font-size: 1.5rem;
    }
  }
}

@include for-extra-small-devices {

  header {
    .form-group {
      margin-bottom: 0;
    }

    .top-bar {
      padding-top: .5rem;
      justify-content: space-between;

      .links {
        padding: 0;
      }

      .login-link {
        padding-right: .5rem;
        margin-right: .5rem;
      }
    }

    .heading {
      font-size: 2rem !important;
      margin: 1rem 0 0;
    }

    .search-container {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .heading {
        padding: 0 1rem;
      }

      .search {
        flex-direction: column;
        align-items: center;
      }

      .logo {
        margin: 0 0 1rem;
        width: calc(100vw - 70vw);
        height: calc(100vw - 70vw)
      }

      input[name="school_name"] {
        max-width: 150px !important;
        min-width: auto !important;
      }
    }

    .navigation-bar {
      border-top: 1px solid $secondary-color;

      .navbar-collapse.show {
        //margin-bottom: 10rem;
      }

      .nav-item {
        border-right: 0;
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: 15px;

        &:hover {
          border-right: 0;
        }

        &.active {
          border-right: 0;
        }
      }
    }
  }

  main {
    padding-bottom: 0;
  }

  section {
    border-bottom: 4px solid $light-grey;
  }

  #schools {
    .school-card {
      width: 60%;
    }
  }

  #gallery {
    padding: 1rem 0 2rem;

    .underline-heading {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      text-transform: uppercase;

      &::before {
        height: 2px;
        bottom: 3px;
        left: 20%;
        right: 20%;
      }
    }

    .gallery-grid {
      grid-template-areas: "main main main" "main main main" "secondary secondary secondary" "secondary secondary secondary" "secondary secondary secondary";

      .main-grid {
        grid-template-rows: repeat(2, 59px);
        grid-template-columns: repeat(3, 1fr);

        .big-image {
          grid-column: 1 / 3;
        }
      }

      .secondary-grid {
        grid-template-rows: repeat(3, 59px);
      }
    }
  }

  #content {
    padding: 0;

    .events {
      .event {
        padding: .5rem;
        flex-direction: column;
      }
    }

    .events-container, .upcoming-events-container, .blogs-container {
      padding: 2rem 0;
      border-bottom: 4px solid $light-grey;
      margin-bottom: 0;
    }

    .upcoming-events-container {
      .upcoming-events {

        .event {
          align-items: stretch;

          .image-details {
            flex-direction: column;
            padding-right: .7rem;
            border-right: 1px solid $secondary-color;

            img {
              width: 100%;
              height: auto;
              max-height: 120px;
              border-radius: 0;
            }

            .time-details {
              border-right: none;
              padding-right: 0;
              align-items: flex-start;
            }
          }
        }
      }
    }

    .blogs-container {
      margin: 0;

      .main-heading-style {
        margin-bottom: 1rem;
      }

      .blog-item {
        margin: 1rem 0;
        flex-direction: column;

        .image {
          margin-bottom: 1rem;
        }

        .details {

          .title {
            font-size: 1rem;
            width: 100%;
          }

          .post-date {
            p {
              padding: 0;
            }
          }

          .description {
            font-size: .75rem;
            line-height: 1.1;
          }
        }
      }
    }
  }

  #counter {
    padding: 2rem 0 0;
    background-size: cover;

    & .counting-item:nth-last-of-type {
      margin-bottom: 0;
    }

    .counting-item {
      flex-direction: column;
      margin-bottom: 2rem;
    }
  }

  #founders-pen {
    padding: 2rem 0;

    .container {
      overflow: hidden;
    }

    .founder-image {
      margin-bottom: 1rem;
    }

    .main-heading-style {
      font-size: 1.6rem;

      &::before {
        width: 30%;
      }
    }

    .desc {
      padding: 0;
    }

    .details {
      width: 100%;
    }

    .description {
      text-align: justify;
      font-size: 1rem;
    }

    .author {
      width: 100%;
      font-size: 1.5rem;
    }
  }

  #testimonials {
    background: url('../img/testimonial-bg-mobile.png') no-repeat;
    background-size: 100% 100%;
    padding: 2rem 0;
    color: inherit;

    .rating-card {
      margin: 0 0 1rem;
    }

    .underline-heading-style {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .basic-details {
      /*.education {
        font-size: 1rem;
      }

      .rating p {
        font-size: 1rem;
      }*/
    }

    /*.description {
      font-size: 1rem;
    }*/

    .add-button {
      padding: 0;
    }
  }

  #bde-overview {

    .heading {
      font-size: 1.8rem;
    }

    .bde-container {
      overflow: hidden;
      flex-wrap: wrap;

      .bde-item {
        flex-basis: 50%;
        margin-bottom: 1rem;

        .profile-image {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }

        &:nth-child(7) {
          display: none;
        }
      }
    }
  }

  #associates {
    padding: 2rem 0 0;

    .col-sm-12 {
      overflow: hidden;
    }

    .main-heading-style {
      margin-bottom: 2rem;
    }

    p {
      font-size: 1rem;
    }

    .associates-logo-container {

      .associates-logo {
        height: auto;
        width: calc((100% / 2) - 1rem);

        img {
          height: auto;
        }
      }
    }
  }
}

@include for-small-devices {

  header {
    .form-group {
      margin-bottom: 0;
    }

    .top-bar {
      padding-top: .5rem;
    }

    .heading {
      font-size: 2rem !important;
      margin: 1rem 0 0;
    }

    .search-container {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .heading {
        padding: 0 1rem;
      }

      .search {
        flex-direction: column;
        align-items: center;
      }

      .logo {
        margin: 0 0 1rem;
        width: calc(100vw - 70vw);
        height: calc(100vw - 70vw)
      }

      input[name="school_name"] {
        max-width: 150px !important;
        min-width: auto !important;
      }
    }

    .navigation-bar {
      border-top: 1px solid $secondary-color;

      .navbar-collapse.show {
        //margin-bottom: 10rem;
      }

      .nav-item {
        border-right: 0;
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: 15px;

        &:hover {
          border-right: 0;
        }

        &.active {
          border-right: 0;
        }
      }
    }
  }

  main {
    padding: 0;
  }

  #schools {
    padding: 2rem 0;

    .schools-container {
      padding: 2rem 0 1rem;
    }

    .school-card {
      width: 90%;
    }

  }

  #gallery {
    padding: 1rem 0 2rem;

    .underline-heading {
      font-size: 3rem;
      margin-bottom: 2rem;

      &::before {
        bottom: 5px;
      }
    }

    .gallery-grid {
      width: 65%;
      margin: 0 auto;
      grid-template-areas: "main main main" "main main main" "secondary secondary secondary" "secondary secondary secondary" "secondary secondary secondary";

      .main-grid {
        grid-template-rows: repeat(2, 59px);
        grid-template-columns: repeat(3, 1fr);

        .big-image {
          grid-column: 1 / 3;
        }
      }

      .secondary-grid {
        grid-template-rows: repeat(3, 59px);
      }
    }
  }

  #content {

    .blogs-container {

      .blog-item {
        align-items: flex-start;
      }
    }
  }

  #testimonials {

    .rating-card {
      padding: .2rem .8rem .8rem;
    }
  ;
  }

  #bde-overview {

    .heading {
      font-size: 1.6rem;
    }

    .bde-container {
      overflow: hidden;
      flex-wrap: wrap;

      .bde-item {
        flex-basis: 33%;
        margin-bottom: 1rem;

        &:nth-child(7) {
          display: none;
        }

        .profile-image {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }

        .profile-details {
          font-size: .9rem;
        }
      }
    }
  }

  #associates {
    padding: 2rem 0 0;
  }
}

@include for-medium-devices {
  header {
    .form-group {
      margin-bottom: 0;
    }

    .top-bar {
      padding-top: .5rem;
    }

    .heading {
      font-size: 2rem !important;
      margin: 1rem 0 0;
    }

    .search-container {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .heading {
        padding: 0 1rem;
      }

      .search {
        flex-direction: column;
        align-items: center;
      }

      .logo {
        margin: 0 0 1rem;
        width: calc(100vw - 70vw);
        height: calc(100vw - 70vw)
      }

      input[name="school_name"] {
        max-width: 150px !important;
        min-width: auto !important;
      }
    }

    .navigation-bar {
      border-top: 1px solid $secondary-color;

      .navbar-collapse.show {
        //margin-bottom: 10rem;
      }

      .nav-item {
        border-right: 0;
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: 15px;

        &:hover {
          border-right: 0;
        }

        &.active {
          border-right: 0;
        }
      }
    }
  }

  section {
    padding: 2rem 0;
  }

  #schools {

    .schools-container {
      padding: 2rem 0;
    }

    .school-card {
      width: 90%;
    }
  }

  #gallery {
    padding: 1rem 0 2rem;

    .underline-heading {
      &::before {
        left: 10%;
        right: 10%;
        height: 3px;
        bottom: 0;
      }
    }
  }

  #content {
    padding: 0;

    .events {
      .event {
        padding: .5rem;
        flex-direction: column;
      }
    }

    .events-container, .upcoming-events-container, .blogs-container {
      padding: 2rem 0;
      border-bottom: 4px solid $light-grey;
      margin-bottom: 0;
    }

    .upcoming-events-container {
      .upcoming-events {
        width: 95%;

        .event {
          align-items: stretch;

          .image-details {
            flex-direction: column;
            padding-right: .7rem;
            border-right: 1px solid $secondary-color;

            img {
              width: 100%;
              height: auto;
              max-height: 120px;
              border-radius: 0;
            }

            .time-details {
              border-right: none;
              padding-right: 0;
              align-items: flex-start;
            }
          }
        }
      }
    }

    .blogs-container {
      margin: 0;

      .main-heading-style {
        margin-bottom: 1rem;
      }

      .blog-item {
        margin: 1rem 0;
        flex-direction: column;

        .image {
          margin-bottom: 1rem;
        }

        .details {

          .title {
            font-size: 1rem;
            width: 100%;
          }

          .post-date {
            p {
              padding: 0;
            }
          }

          .description {
            font-size: .75rem;
            line-height: 1.1;
          }
        }
      }
    }
  }

  #founders-pen {

    .desc {
      padding-left: 0;
    }

    .details {
      width: 100%;
    }
  }

  #testimonials {

    .underline-heading-style {
      font-size: 1.5rem;

      &::before {
        left: 40%;
        right: 40%;
      }
    }

    .description {
      line-height: 1.2;
    }
  }

  #bde-overview {
    padding: 2rem 0;

    .bde-container {
      .bde-item {
        flex-basis: 25%;
        margin-bottom: 1rem;
      }
    }
  }

  #associates {
    padding: 2rem 0 0;

    .associates-details {
      overflow: hidden;
    }

    .associates-logo-container {
      justify-content: flex-start;

      .associates-logo {
        width: calc(100% / 3 - 1rem);
      }
    }
  }

  .floating-contact {
    width: 80%;
    left: 10%;
  }
}
