#bde-form {
  .main-heading-style {
    @extend .main-heading-style;
    font-size: 2rem;
    text-transform: none;
    margin-bottom: 2rem;
  }

  .special-inside-icon {
    @extend .icon-inside-right;

    input {
      width: 175px;
    }

    i {
      right: 185px;
    }
  }

  #datepicker {
    width: 276px;
  }
  .special-inside-icon {
    .input-group {
      width: 276px;
    }

    input {
      width: 276px;
    }

    i {
      right: 40%;
    }
  }

  @include for-extra-small-devices {
    .special-inside-icon {

      .input-group {
        width: 100% !important;
      }

      input {
        width: 100%;
      }

      i {
        right: 10px;
      }
    }

    #datepicker {
      width: 100%;
    }
  }

  @include for-small-devices {
    .special-inside-icon {
      .input-group {
        width: 100%;
      }

      input {
        width: 100%;
      }

      i {
        right: 10px;
      }
    }

    #datepicker {
      width: 100%;
    }
  }

  @include for-medium-devices {
    .special-inside-icon {
      .input-group {
        width: 100%;
      }

      input {
        width: 100%;
      }

      i {
        right: 10px;
      }
    }
  }

  @include for-large-devices {
    .special-inside-icon {
      i {
        right: 30%;
      }
    }
  }
}

#student-form {

}

.contact-form {

  @include for-extra-small-devices {
    margin-top: 2rem;
  }
}

@include for-small-devices {
  .social-links {
    .links-container {

      li {
        flex-basis: 15%;
      }
    }
  }
}

@include for-medium-devices {
  .social-links {
    .links-container {
      &.contact-page {
        a {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
