$border-bottom: 1px solid lighten($grey, 15%);
.sidebar {
  padding: 0 1rem 0 0;
  margin-top: 1rem;
  border-right: 1px solid $grey;

  * {
    color: $black;
  }

  .pre-header {
    display: none;

    .icon-inside-right {
      i {
        transition: all 300ms ease-in-out;
      }

      input {
        * {
          transition: all 300ms ease-in-out;
        }

        &:hover {
          border-color: $secondary-color;
          box-shadow: 0 0 11px transparentize($secondary-color, .9);
        }

        &:focus {
          border-color: $secondary-color;
          box-shadow: 0 0 0 0.2rem transparentize($secondary-color, .8);

          + i {
            color: $primary-color;
          }
        }
      }
    }
  }

  .mobile-filter-trigger-container {
    display: none;
  }

  .mobile-filter-controls {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border-bottom;
    padding: 0 1rem;
    margin-bottom: 1rem;

    h3, .heading {
      font-size: 1.3rem;
      margin-bottom: .2rem;
      flex-basis: 90%;
    }

    i, .icon {
      flex-basis: 10%;
      text-align: right;
    }
  }

  .ranking {
    overflow: hidden;
    padding-bottom: 1rem;
    border-bottom: $border-bottom;

    .header {
      border-bottom: 0;
      margin-bottom: 0;
    }

    h3, .heading {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .content {
      overflow-y: auto;
      max-height: 120px;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;

      &.show {
        display: flex;
      }

      .box {
        width: 100%;
      }

      .hidden-content {
        display: none;

        &.show {
          display: block;
        }
      }
    }

    .form-group {
      padding: 0 1rem;
      margin: 0;

      label {
        margin-bottom: .1rem;
        font-weight: lighter;
        font-size: .8rem;
      }
    }

    .toggle-extra {
      color: $black;
      text-decoration: underline;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $grey;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $secondary-color;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $secondary-color;
    }
  }

  .rating {
    @extend .ranking;
    margin-top: 1rem;

    .form-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .star-rating-container {
        flex-basis: 95%;
        display: flex;
        align-items: center;

        i {
          font-size: .8rem;
          color: $grey;
        }

        .text {
          margin: 0;
          padding: 0 0 0 .4rem;
          font-size: 1rem;
        }
      }

      input {
        flex-basis: 5%;
      }
    }
  }

  .school-type, .school-location, .school-medium, .school-features {
    @extend .ranking;
    margin-top: 1rem;

    .form-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .school-strength {
    @extend .ranking;
    margin-top: 1rem;

    .form-group {
      text-align: center;
    }

    .custom-range::-webkit-slider-thumb {
      background: $secondary-color;
    }

    .custom-range::-moz-range-thumb {
      background: $secondary-color;
    }
  }

  .school-features {
    border-bottom: 0;
  }
}

.schools-list {
  margin-top: 1rem;
  padding: 0;

  .top-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: .3rem;
    border-bottom: $border-bottom;

    .icon-inside-right {
      i {
        transition: all 300ms ease-in-out;
      }

      input {
        * {
          transition: all 300ms ease-in-out;
        }

        &:hover {
          border-color: $secondary-color;
          box-shadow: 0 0 11px transparentize($secondary-color, .9);
        }

        &:focus {
          border-color: $secondary-color;
          box-shadow: 0 0 0 0.2rem transparentize($secondary-color, .8);

          + i {
            color: $primary-color;
          }
        }
      }
    }
  }

  .info {
    //width: 100%;
    margin: 0;
    flex-basis: 10%;
  }

  .search-box {
    flex-basis: 40%;
    width: 100%;
    //display: none;
  }

  .search-input {
    border-radius: 20px;
  }

  .school-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .3rem .4rem;
    border: $border-bottom;
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 3px transparentize($pure-black, .9);
    -moz-box-shadow: 2px 2px 3px transparentize($pure-black, .9);
    box-shadow: 2px 2px 3px transparentize($pure-black, .9);

    .image-and-details {
      flex-basis: 70%;
      display: flex;
      justify-content: flex-start;
      //align-items: center;
      flex-wrap: wrap;
      padding-right: .5rem;
      border-right: $border-bottom;

      .image {
        flex-basis: calc(45% - .5rem);
        border-radius: 10px;
        overflow: hidden;
        margin-right: .5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .primary-details {
        flex-basis: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          @extend .underline-heading-style;
          font-size: .8rem;
          color: $black;
          text-shadow: 0 0 0;
          font-weight: normal;

          &::before {
            height: 2px;
            bottom: -5px;
            background-color: $secondary-color;
          }
        }

        .basic-info {
          padding: 0;
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 0;

          li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            //align-items: center;
            font-size: .666rem;
            margin-bottom: .1rem;

            span {
              display: block;
              width: 50%;
            }
          }
        }
      }

      .extra-info {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .tags {
          p {
            margin: 0;
            color: darken($grey, 10%);
            font-size: .7rem;
          }
        }

        .contact-info {
          font-size: .8rem;
          color: $secondary-color;
          display: flex;

          .hover-slide-left {
            display: flex;

            &:first-of-type i {
              border-right: $border-bottom;
              padding-right: .4rem;
              margin: 0;
            }

            &:last-of-type i {
              padding-left: .2rem;
            }

            .slide-this {
              //display: none;
              display: block;
              transition: all 200ms ease-in-out;

              &.show {
                display: block;
              }
            }
          }

          /*i {
            &:first-of-type {
            }
          }*/
        }
      }
    }

    .details-option {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .favourite {
        color: $grey;
        width: 100%;
        text-align: right;
        line-height: .5;

        & i {
          font-size: .8rem;

          &.active {
            color: var(--danger);
          }
        }
      }

      .rating-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: .9rem;

        h3 {
          font-size: 1rem;
          font-weight: bold;
          margin: 0;
          line-height: 1;
        }

        .star-rating-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          line-height: .7;
          margin-bottom: .2rem;

          i {
            font-size: .7rem;
          }

          span {
            font-size: .7rem;
            padding: 0 0 0 .3rem;
          }
        }

        .total-reviews {
          font-size: .666rem;
        }
      }

      .details-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > .btn {
          margin-bottom: .3rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .btn-details {
          @extend .btn-round-secondary;
          font-size: .666rem;
          text-transform: none;
          padding: .05rem .5rem;
          border-radius: 5px;
          font-weight: normal;

          &.active {
            background-color: $white;
            box-shadow: 2px 2px 3px transparentize($pure-black, .9);
          }
        }
      }
    }
  }

  @media screen and (min-width: 767px) {
    margin-bottom: 2rem;
  }
}

@include for-extra-small-devices {
  .sidebar {
    border-right: none;
    padding: 0;
    
    .pre-header {
      display: block;
    }

    .hide-mobile {
      display: none;
    }

    .mobile-filter-trigger-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 4px solid $light-grey;

      .mobile-filter-trigger, .total-result {
        padding: .3rem 1rem;
        border: 1px solid $light-grey;
        box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.1), -2px -2px 10px rgba(60, 53, 51, 0.1);
      }

      .total-result {
        box-shadow: none;
      }

      .mobile-filter-trigger {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          margin-left: 1rem;
        }
      }
    }

    .mobile-filters-container {
      padding-top: 2rem;

      .mobile-filter-controls {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 2rem;

        .clear-button {
          color: $primary-color;
          text-decoration: underline;
          font-size: 1.3rem;
        }
      }
    }

    .rating, .ranking, .header {
      border-bottom: 4px solid $light-grey;
    }

  }

  .schools-list {
    .top-details {
      display: none;
    }

    .school-card {
      flex-direction: column;

      .image-and-details {
        flex-basis: 100%;
        padding: 0 0 .5rem 0;
        border-right: none;
      }

      .details-option {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        border-top: 1px solid $grey;
        padding: .5rem 0 0;

        & > * {
          flex-basis: 35%;
        }

        .favourite {
          flex-basis: 15%;
        }

        .rating-details {
          order: 1;
        }

        .favourite {
          order: 2;
          text-align: center;

          i {
            font-size: 1.5rem;
          }
        }

        .details-buttons {
          order: 3;
        }
      }
    }
  }
}

@include for-small-devices {
  .sidebar {
    border-right: none;
    padding: 0;

    .pre-header {
      display: block;
    }

    .hide-mobile {
      display: none;
    }

    .mobile-filter-trigger-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 4px solid $light-grey;

      .mobile-filter-trigger, .total-result {
        padding: .3rem 1rem;
        border: 1px solid $light-grey;
        box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.1), -2px -2px 10px rgba(60, 53, 51, 0.1);
      }

      .total-result {
        box-shadow: none;
      }

      .mobile-filter-trigger {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          margin-left: 1rem;
        }
      }
    }

    .mobile-filters-container {
      padding-top: 2rem;

      .mobile-filter-controls {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 2rem;

        .clear-button {
          color: $primary-color;
          text-decoration: underline;
          font-size: 1.3rem;
        }
      }
    }

    .rating, .ranking, .header {
      border-bottom: 4px solid $light-grey;
    }

  }

  .schools-list {
    .top-details {
      display: none;
    }

    .school-card {

      .image-and-details {

        .primary-details {

          .title {
            font-size: 1rem;
          }

          .basic-info {
            li {
              font-size: .9rem;
            }
          }
        }
      }

      .details-option {

        .rating-details {

          .total-reviews {
            font-size: .8rem;
          }
        }

        .details-buttons {

          & > .btn {
            margin-bottom: .6rem;
          }

          .btn-details {
            font-size: .9rem;
          }
        }
      }
    }
  }
}

@include for-medium-devices {
  .sidebar {

    .pre-header {
      display: none;
    }

    .mobile-filters-container {
      display: block;
    }
  }

  .schools-list {

    .top-details {

      .search-box {
        display: block;
      }
    }

    .school-card {
      flex-direction: column;

      .image-and-details {
        border-right: none;
        padding-bottom: .5rem;
      }

      .details-option {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        border-top: 1px solid #bbb;
        padding: .5rem 0 0;

        .rating-details, .details-buttons {
          flex-basis: 35%;
        }

        .rating-details {
          order: 1;
          margin: 0;

          & > * {
            margin-bottom: .4rem !important;
          }
        }

        .favourite {
          order: 2;
          text-align: center;

          i {
            font-size: 1.3rem;
          }
        }

        .details-buttons {
          order: 3;

          & > .btn {
            margin-bottom: .6rem;
          }
        }

        .favourite {
          flex-basis: 15%;
        }
      }
    }
  }
}

@include for-large-devices {

  .sidebar {
    .mobile-filters-container {
      display: block;
    }
  }
}

@include for-xl-devices {

  .sidebar {
    .mobile-filters-container {
      display: block;
    }
  }
}
