#notification-details-page {
  .notification-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .notification-image {
      margin-bottom: 2rem;
      width: 100%;
      text-align: center;

      img {
        border: 0.5rem solid #fff;
        box-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1)
      }
    }

    .notification-content {
      .title {
        font-size: 2rem;
        color: $font-color;
        font-weight: bold;
        text-align: center;
        position: relative;
        margin-bottom: 1rem;

        &::after {
          content: "";
          bottom: -10px;
          background-color: $secondary-color;
          height: 3px;
          position: absolute;
          left: 35%;
          right: 35%;
        }
      }

      .notification-date {
        font-size: .9rem;
        color: $font-color;
        margin-bottom: 1rem;
      }

      .notification-description {
        width: 100%;
        font-size: .9rem;
        line-height: 1.3;
      }
    }
  }
}
