#nsi {
  overflow: hidden;

  .main-heading-style {
    @extend .main-heading-style;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    color: $black;

    &::before {
      bottom: 5px;
      height: 2px;
    }
  }

  .description {
    font-size: .9rem;
    color: $font-color;
    line-height: 1.3;
  }

  .nsi-image {
    width: 100%;
    margin: 2rem 0 3rem;

    svg {
      width: 100%;
    }
  }

  .nsi-list {

    .description {
    }

    ul {
      padding-inline-start: 20px;
    }

    li {
      list-style: none;
      margin-bottom: 2rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: -20px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primary-color;
      }

      img {
        width: 70%;
        margin-bottom: 1rem;
        box-shadow: $normal-shadow;
      }
    }

    .nsi-list-item {

    }

    .nsi-item-header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .image {
        flex-basis: 100px;
        margin-right: 1rem;

        img {
          height: 100px;
          width: 100px;
        }
      }

      h3, .title {
        flex-basis: calc(100% - (100px + 1rem));
      }

      a {
        color: $font-color;
      }
    }

    .nsi-item-content {
      .description {
        margin-bottom: 1rem;
      }
    }
  }

  @include for-extra-small-devices {
    .nsi-image {
      margin: 2rem 0 2rem;
    }
  }
}
