#gallery-inner {
  padding: 3rem 0;

  .title {
    @extend .main-heading-style;
    font-size: 1.8rem;
    text-transform: none;

    &::before {
      height: 2px;
      bottom: 5px;
      width: 100px;
    }
  }

  .gallery-details {
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .description {
    line-height: 1.2;
  }

  .gallery-images-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;

    .gallery-image {
      flex-basis: 20%;
      display: block;
      padding: 0 .5rem;
      margin: 0 0 1rem;

      &:hover {
        text-decoration: none;

        img {
          box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.3), -2px -2px 10px rgba(60, 53, 51, 0.3);
        }
        .caption {
          text-shadow: 2px 2px 10px rgba(60, 53, 51, 0.3), -2px -2px 10px rgba(60, 53, 51, 0.3);
        }
      }

      img {
        border: 3px solid $white;
        box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.1), -2px -2px 10px rgba(60, 53, 51, 0.1);
        margin-bottom: 1rem;
        transition: all ease-in-out 300ms;
      }

      .caption {
        color: $black;
        text-align: center;
        margin-bottom: 1.5rem;
        line-height: 1;

        * {
          margin: 0;
        }
      }
    }

  }

  @include for-extra-small-devices {
    .gallery-images-container {
      flex-direction: column;

      .gallery-image {

        img {
          margin-bottom: .5rem;
        }

        .caption {
          font-size: 1.2rem;
          margin-bottom: .5rem;
        }
      }
    }
  }

  @include for-small-devices {
    .gallery-images-container {
      flex-direction: row;

      .gallery-image {
        flex-basis: 33%;
        padding: 0 .4rem;

        img {
          margin-bottom: .5rem;
        }

        .caption {
          font-size: 1.2rem;
          margin-bottom: .5rem;
        }
      }
    }
  }

  @include for-medium-devices {
    .gallery-images-container {
      flex-direction: row;

      .gallery-image {
        flex-basis: 25%;
        padding: 0 .5rem;

        img {
          margin-bottom: .5rem;
        }

        .caption {
          margin-bottom: .5rem;
        }
      }
    }
  }

  /*@include for-large-devices {
    .gallery-images-container {
      flex-direction: row;

      .gallery-image {

        img {
          margin-bottom: .5rem;
        }

        .caption {
          font-size: 1.2rem;
          margin-bottom: .5rem;
        }
      }
    }
  }*/
}
