#gallery-page {
  margin: 2rem 0;

  .title {
    @extend .main-heading-style;
    font-size: 1.7rem;
    text-transform: none;
    margin-bottom: 1rem;

    &::before {
      bottom: 5px;
      height: 2px;
    }
  }

  .title-alternate {
    @extend .underline-heading-style;
    font-size: 1.7rem;
    text-transform: none;
    margin-bottom: 1rem;
    color: $font-color;
    text-shadow: none;

    &::before {
      height: 2px;
      background-color: $secondary-color;
      left: calc(50% - 50px);
      right: calc(50% - 50px);
      bottom: -2px;
    }
  }

  .moment-item {
    margin: 3rem 0;
    align-items: baseline;
    flex-direction: row;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .moment-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .description {
      font-size: .9rem;
      margin-bottom: 1rem;
    }
  }

  .all-image-section {
    align-items: center;

    .details {
      @extend .moment-details;
    }
  }

  .all-gallery-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .grid-image {
      flex-basis: calc((100% / 6) - 1rem);
      width: 100%;
      margin: .5rem;

      img {
        width: 100%;
      }
    }
  }

  .gallery-events {
    margin: 3rem 0;

    .gallery-events-details {
      text-align: center;
      margin-bottom: 1rem;

      p {
        margin: 0 auto;
        line-height: 1.2;
        width: 85%;
        font-size: .9rem;
      }
    }

    .title-alternate {
      font-size: 2rem;

      &::before {
        left: calc(50% - 30px);
        right: calc(50% - 30px);
      }
    }
  }

  .gallery-category-container {

    .gallery-category {
      display: block;
      margin: 1.5rem 0;

      &:hover {
        text-decoration: none;

        figcaption {
          transform: translateY(0);
        }
      }

      figure {
        margin: 0;
        position: relative;
        overflow: hidden;
      }

      figcaption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparentize($black, .8);
        padding: 1rem;
        transform: translateY(-100%);
        transition: 300ms all ease-in-out;
      }

      .title-alternate {
        color: $white;
        padding-left: 1rem;
        text-align: left;

        &::before {
          left: 1rem;
          width: 50px;
          bottom: -5px;
          right: initial;
        }
      }

      img {
        box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.1), -2px -2px 10px rgba(60, 53, 51, 0.1);
      }
    }

    .gallery-category-title {
      text-align: center;
      color: $black;
      margin-top: 1.2rem;
    }
  }
}
