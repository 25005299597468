@import "mixins";

section, aside {
  padding: 3rem 0;

  @include for-extra-small-devices {
    padding: 2rem 0;
  }

  @include for-small-devices {
    padding: 2rem 0;
  }
}

.divider {

  &-v {
    content: "|";
    color: $white;
    padding: 0 1rem;
    background-color: transparent;
  }

  &-h {
    content: "-";
    color: $white;
    padding: 0 1rem;
    background-color: transparent;
  }
}

.custom-bg {
  &-primary {
    background-color: $primary-color;
  }

  &-secondary {
    background-color: $secondary-color;
  }
}

.custom-color, .custom-text {
  &-primary {
    color: $primary-color !important;
  }

  &-secondary {
    color: $secondary-color !important;
  }
}

.btn-custom-secondary {
  color: $primary-color;
  background-color: $secondary-color;
}

.btn-round {
  &-secondary {
    color: $primary-color;
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 20px;

    &:hover {
      background-color: $white;
      box-shadow: 2px 2px 3px transparentize($pure-black, .9);
    }
  }

  &-primary {
    color: $white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 1.4rem;

    &:hover {
      background-color: $secondary-color;
      color: $primary-color;
      border: 1px solid $secondary-color;
      box-shadow: 2px 2px 3px transparentize($pure-black, .9);
    }
  }
}

.main-heading-style {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: $font-color;
  text-align: left;
  padding-right: 1rem;
  position: relative;
  display: inline-block;

  &::before {
    position: absolute;
    content: "";
    left: 100%;
    width: 50%;
    bottom: 10px;
    height: 3px;
    background-color: $secondary-color;
  }
}

.underline-heading-style {
  color: $white;
  font-size: 5rem;
  font-weight: bold;
  text-transform: none;
  position: relative;
  text-align: center;
  text-shadow: 2px 2px 10px transparentize($black, .7);

  &::before {
    position: absolute;
    content: "";
    height: 4px;
    bottom: 0;
    left: 25%;
    right: 25%;
    background-color: $black;
  }
}

.custom-pagination {
  display: flex;
  list-style: none;
  padding: 0 !important;
  margin: 0;

  .page-item {
    &:last-of-type .page-link {
      margin-right: 0;
    }
  }

  .page-link {
    border-radius: 50% !important;
    margin-right: 1rem;
    color: $black;
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 2px 2px 3px transparentize($pure-black, .9);

    &:hover, &.active {
      background-color: $secondary-color;
      color: $primary-color
    }
  }
}

.time-details {
  padding-right: .7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-right: 1px solid $secondary-color;

  .date-details {
    .date {
      color: $secondary-color;
      font-weight: bold;
      font-size: 2.5rem;
    }

    .month {
      color: $black;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .time {
    font-size: .9rem;
    color: $black;
    text-transform: uppercase;
  }
}

.star-rating {
  i {
    &.active {
      color: $secondary-color !important;
    }
  }
}

.form-rounded {
  border-radius: 1rem;
}

.icon-inside {
  &-right {
    position: relative;
    width: 100%;

    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }

    input, select {
      padding-right: 2rem;

      &:focus + i {
        color: $black;
      }
    }

    i {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey;
    }
  }

  &-left {
    position: relative;
    width: 100%;

    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }

    input, select {
      padding-left: 3rem;

      &:focus + i {
        color: $black;
      }
    }

    i {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey;
    }
  }
}

.form-rounded {
  border-radius: 1rem;
}

.form-label {
  margin: 0.5rem 0;
}

.form-width {
  width: 175px;

  @include for-extra-small-devices {
    width: 100%;
  }

  @include for-small-devices {
    width: 100%;
  }

  @include for-medium-devices {
    width: 100%;
  }
}

.form-width-wide {
  width: 276px;

  @include for-extra-small-devices {
    width: 100%;
  }

  @include for-small-devices {
    width: 100%;
  }

  @include for-large-devices {

  }

}

.text-rounded {
  border-radius: 20px 20px 0 0;
}

.text-rounded-inverse {
  border-radius: 0 0 20px 20px;
}

.input-bottom {
  border: 0;
  border-bottom: 1px solid $pure-black;
  border-radius: 0;

  &:hover {
    border-radius: 1rem;
    border: 1px solid lightblue;
    box-shadow: 0 0 11px lightblue;
  }
}

.input-rounded {
  border-color: $pure-black;
  border-radius: 1rem;

  &:hover {
    border: 1px solid lightblue;
    box-shadow: 0 0 11px lightblue;
  }
}

.contact-heading {
  font-size: 22px;
}

.input-icon {
  border: 0;
  background: none;
  border-bottom: 1px solid $pure-black;
  border-radius: 0;
}

.less-height-input {
  height: calc(1.5em + .3rem + 2px);
}

.less-height-form {
  input, select {
    height: calc(1.5em + .3rem + 2px);
  }
}

.social-links {

  .links-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content: space-around;

    &.contact-page {
      a {
        margin: 0 1.2rem 1rem;
      }
    }

    li {
      flex-basis: 20%;

      img {
        display: block;
        width: 100%;
      }
    }

    a {
      display: inline-block;
      margin: 0 .8rem 1rem;
    }
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 60px;
  }

  .loader-wheel {
    animation: spin 1s infinite linear;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-left: 4px solid $primary-color;
    border-radius: 50%;
    height: 50px;
    margin-bottom: 10px;
    width: 50px;
  }

  .loader-text {
    color: $black;
    font-family: arial, sans-serif;
  }

  .loader-text:after {
    content: 'Loading';
    animation: load 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  67% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
}

/*.modal-dialog-scrollable {
  !* width *!
  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 1rem;
  }

  !* Track *!
  ::-webkit-scrollbar-track {
    background: lighten($grey, 10%);
  }

  !* Handle *!
  ::-webkit-scrollbar-thumb {
    background: $secondary-color;
  }

  !* Handle on hover *!
  ::-webkit-scrollbar-thumb:hover {
    background: darken($secondary-color, 20%);
  }
}*/
