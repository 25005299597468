.events-tab {
  margin: 2rem 0 1rem;
  border-bottom: 2px solid $primary-color;

  .nav-item {
    margin-bottom: -2px;
  }

  .nav-link {
    display: block;
    border: 2px solid $grey;
    border-bottom-color: transparent;
    color: $font-color;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 .6rem;
    position: relative;
    transition: all 300ms ease-in-out;

    &:hover {
      border-bottom-color: transparent;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &.active {
      border-color: $primary-color $primary-color transparent $primary-color;
      color: $primary-color;
    }
  }
}

.events-container {

  .main-heading-style {
    @extend .main-heading-style;
    font-size: 2rem;
    margin-bottom: 2rem;

    &::before {
      width: 30%;
    }
  }

  .event {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    //align-items: flex-end;
    padding: 2rem 3rem;

    .image {
      flex-basis: 35%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }

      .time-details {
        line-height: 1;
        //margin-top: 2rem;
        padding-right: .7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $secondary-color;
      }

      .date-details {
        .date {
          color: $secondary-color;
          font-weight: bold;
          font-size: 2.5rem;
        }

        .month {
          color: $black;
          text-transform: uppercase;
          font-size: 1.5rem;
          font-weight: bold;
        }
      }

      .time {
        font-size: .8rem;
        color: $black;
        text-transform: uppercase;
      }
    }

    .event-details {
      padding-left: .7rem;
      flex-basis: calc(100% - (50%));
      font-size: .8rem;
      display: flex;
      align-items: flex-start;

      .time-details {
        flex-basis: 30%;
      }

      .main-details {
        flex-basis: 100%;
        padding-left: .7rem;
      }

      h6, .heading {
        color: $primary-color;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: .3rem;
      }

      p.details {
        font-size: .9rem;
        color: $black;
        margin: 0 0 .4rem;
        line-height: 1.3;
      }

      .address {
        font-size: .8rem;
        color: $primary-color;
        font-weight: bold;
        margin: 0;
        line-height: 1.3;

        &::before {
          content: "\f3c5";
          font-family: "Font Awesome 5 Free";
          color: $secondary-color;
          font-size: .9rem;
          padding-right: .2rem;
        }
      }
    }

    .details-button {
      flex-basis: 15%;
      display: flex;
      align-items: flex-end;

      .btn-round-secondary {
        font-size: .8rem;
      }
    }
  }
}

@include for-extra-small-devices {
  .events-tab {
    margin-top: 1rem;

    .nav-item {
      margin: 0 0 -3px;
    }

    .nav-link {
      padding: .3rem;
    }
  }

  .events-container {

    /*.event {
      align-items: flex-start;
      justify-content: space-between;
      padding: 2rem 0;
      border-bottom: 4px solid $light-grey;
      flex-wrap: wrap;

      .image {
        flex-basis: 100px;
        flex-direction: column;
        margin-bottom: 1rem;

        img {
          width: 100px !important;
          height: 100px !important;
        }
      }

      .event-details {
        flex-basis: calc(100% - 110px);
        padding: 0;

        .time-details {
          border-right-width: 2px;
          padding-right: .5rem;

          .date-details {
            margin-bottom: .3rem;

            .date {
              font-size: 2rem !important;
            }

            .month {
              font-size: .8rem !important;
            }
          }

          .time {
            font-size: .7rem !important;
          }
        }

        .main-details {
          flex-basis: auto;
          text-align: justify;
          padding-left: .5rem;
        }

        h6, .heading {
          font-size: 1rem !important;
          margin: 0;
        }

        p.details {
          font-size: .8rem;
          margin-bottom: .2rem;
          line-height: 1.2;
        }

        .address {
          font-size: .8rem !important;
          font-weight: normal;

          &::before {
            font-size: .9rem !important;
            margin: 0;
            padding: 0;
          }
        }
      }

      .details-button {
        flex-basis: 100%;
        padding: 1rem 0 0;
        text-align: right;
        margin: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }*/


    .event {
      padding: 0 .5rem;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      align-items: flex-start;

      .image {
        flex-direction: column;
        padding-right: .7rem;
        border-right: 1px solid $secondary-color;
        flex-basis: 44%;

        .event-image {
          width: 100%;
        }

        img {
          width: 100%;
          height: auto;
          max-height: 120px;
          border-radius: 0;
        }

        .time-details {
          border-right: none;
          padding-right: 0;
          align-items: flex-start;

          .date {
            font-size: 2rem;
          }

          .month {
            font-size: 1rem;
          }

          .time {
            font-size: .7rem;
          }
        }
      }

      .event-details {
        flex-basis: 55%;

        .main-details {
          flex-basis: 100%;
          padding: 0;
        }

        h6, .heading {
          font-size: 1rem;
        }

        p.details {
          font-size: .8rem;
        }

        .address {
          font-size: .7rem;

          &::before {
            font-size: .8rem;
          }
        }
      }

      .details-button {
        margin-top: 1rem;
        margin-left: 0;
        flex-basis: 100%;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
}

@include for-small-devices {

  .events-container {
    overflow: hidden;
    padding-top: 1rem;

    .main-heading-style {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .event {
      padding: 0 .5rem;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      align-items: flex-start;

      .image {
        flex-direction: column;
        padding-right: .7rem;
        border-right: 1px solid $secondary-color;
        flex-basis: 34%;

        .event-image {
          width: 100%;
        }

        img {
          width: 100%;
          height: auto;
          max-height: 120px;
          border-radius: 0;
        }

        .time-details {
          border-right: none;
          padding-right: 0;
          align-items: flex-start;

          .date {
            font-size: 2rem;
          }

          .month {
            font-size: 1rem;
          }

          .time {
            font-size: .8rem;
          }
        }
      }

      .event-details {
        flex-basis: 65%;

        .main-details {
          flex-basis: 100%;
        }

        h6, .heading {
          font-size: 1.5rem;
        }

        p.details {
          font-size: .95rem;
        }

        .address {
          font-size: .7rem;

          &::before {
            font-size: .8rem;
          }
        }
      }

      .details-button {
        margin-top: .5rem;
        margin-left: 0;
        flex-basis: 100%;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
}

@include for-medium-devices {
  .events-container {

    .event {
      padding: 2rem 1rem;
      align-items: flex-start;
      flex-wrap: wrap;

      .image {
        flex-basis: 50%;
      }

      .event-details {
        flex-basis: calc(100% - 50%);

        .main-details {
          flex-basis: 100%;
          text-align: justify;
        }
      }

      .details-button {
        flex-basis: 100%;
        text-align: right;
        margin: 0;
        justify-content: flex-end;
      }
    }
  }
}
