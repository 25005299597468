#faq-page {
  .heading {
    @extend .main-heading-style;
    font-size: 2rem;
    margin-bottom: 2rem;

    &::before {
      width: 150px;
      height: 3px;
    }
  }

  .content {

    .faq-panel {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .faq-question-container {
      width: 100%;
      border: 1px solid $light-grey;
      padding: .5rem 1rem;
      margin-bottom: 1rem;

      &:last-of-type {
        border-bottom: 1px solid $light-grey;
      }
    }

    .faq-question {
      width: 100%;
      display: block;
      text-decoration: none;
      color: $black;
    }

    .faq-answer {
      width: 100%;
      font-size: .9rem;
      padding: .5rem 1rem;
    }
  }
}
