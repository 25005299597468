#blog-details {

  .main-heading-style {
    font-size: 2rem;
    color: $heading-color;
    text-transform: none;

    &::before {
      bottom: 5px;
    }
  }

  .blog-details {

    .blog-image-details {

      .main-image {
        width: 100%;
        border: .5rem solid $white;
        box-shadow: $light-shadow;
      }

      .blog-author-details {
        position: relative;
        top: -60px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: -45px;

        .author-image {
          display: block;
          border-radius: 50%;
          overflow: hidden;
          height: 110px;
          width: 110px;
          border: 3px solid $white;
        }

        .name {
          margin: 0;
          color: $font-color;
          text-align: center;

          strong {
            text-transform: uppercase;
            color: $pure-black;
          }
        }
      }
    }

    .blog-content {
      overflow: hidden;
      text-align: center;

      .blog-heading {
        @extend .underline-heading-style;
        font-size: 2rem;
        color: $font-color;
        text-shadow: none;
        padding: 1rem 0;

        &::before {
          background-color: $secondary-color;
          height: 2px;
          left: 40%;
          right: 40%;
        }
      }

      .main-heading-style {
        font-size: 1.7rem;
        margin-bottom: 2rem;

        &::before {
          bottom: 5px;
        }
      }

      .description {
        font-size: .8rem;
      }

      .blog-basic-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        .post-date {
          color: $light-black;
          font-size: .8rem;

          &::before {
            content: "\2022";
            margin-right: .2rem;
            color: #fdc800;
            font-weight: bold;
          }
        }

        .blog-author {
          text-align: right;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: bold;
          color: $heading-color;
          font-style: italic;

          &::before {
            content: "-";
            font-weight: bold;
            color: $heading-color;
          }
        }
      }

      .blog-additional-info {
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 0;

        .tags {
          display: flex;
          flex-basis: 70%;
          margin-bottom: 1rem;

          * {
            margin: 0;
          }

          h6 {
            font-weight: bold;
            margin-right: .5rem;
            text-transform: uppercase;
            font-size: .9rem;
          }

          p {
            font-weight: normal;
            font-size: .9rem;
            line-height: 1;
            color: $light-black;
            text-align: left;
          }
        }

        .share {
          @extend .tags;
          flex-basis: 30%;
          text-align: right;
          margin-bottom: 0;

          .share-icons {
            line-height: 1;
            display: flex;
            justify-content: space-around;
            width: 50%;

            i {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  .blog-sidebar {
    overflow: hidden;

    .main-heading-style {
      //text-transform: uppercase;
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }

    .related-posts-container {
      .post-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        .image {
          flex-basis: 40%;

          img {
            width: 100%;
          }
        }

        .details {
          flex-basis: 60%;

          p {
            padding: 0 0 0 .9rem;
            margin: 0;
            font-size: .77rem;
            color: $black;
          }
        }

        .read-more {
          margin-left: 45%;
          font-size: .8rem;
          color: $black;
          text-decoration: underline;
          font-style: italic;
        }
      }
    }
  }

  .popular-blogs {
    margin: 4rem 0 4rem;
    overflow: hidden;

    .main-heading-style {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      //color: $font-color;

      &::before {
        bottom: 5px;
      }
    }

    .popular-blog {
      img {
        width: 100%;
        margin-bottom: .2rem;
      }

      h6, .title {
        font-size: .9rem;
        color: $black;
      }
    }
  }

  .comments {
    margin-bottom: 4rem;

    .main-heading-style {
      font-size: 1.5rem;
      margin-bottom: 2rem;

      &::before {
        bottom: 5px;
      }
    }

    .comment {
      display: flex;
      flex-direction: column;

      .comment-details {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .comment-image {
          flex-basis: 50px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .comment-basic-details {
          margin-left: .5rem;
        }

        .name {
          font-weight: bold;
          font-size: 1.1rem;
          margin-bottom: 0;
          color: $black;
        }

        .date-of-comment {
          font-size: .8rem;
        }
      }

      .comment-description {
        padding: .5rem 1rem;
        font-size: .8rem;
        display: flex;
        align-items: flex-end;
      }

      .btn-round-secondary {
        padding: 0 .4rem;
        font-size: .8rem;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  .add-new-comment {

    .main-heading-style {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 767px) {

    .related-blogs {
      margin: 2rem 0;

      &:last-of-type .related-blog {
        margin-bottom: 0;
      }

      .related-blog {
        margin-bottom: 1rem;
      }
    }

    .comments {
      margin-bottom: 2rem;

      .comment {
        margin: 0 0 2rem 0;

        &:last-of-type {
          margin-bottom: 0;
        }

        .comment-description {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    .add-new-comment {
      overflow: hidden;
    }
  }

  @include for-extra-small-devices {
    border-bottom: none;

    .blog-details {
      border-bottom: 4px solid $light-grey;

      .blog-basic-details {
        margin-bottom: 1rem;
      }

      .blog-content {

        .blog-heading {
          margin-bottom: .5rem;
          font-size: 1.2rem;
          padding-bottom: .5rem;
        }

        .blog-additional-info {
          border-bottom: 0;
        }
      }
    }

    .blog-sidebar {
      margin: 2rem 0 0;
      border-bottom: 4px solid $light-grey;

      .post-item {
        padding: .7rem .5rem;
        border: 1px solid $grey;
        box-shadow: 12px 3px 10px rgba(60, 53, 51, 0.1), 12px 3px 10px rgba(60, 53, 51, 0.1);

        .details {
          p {
            font-size: 1rem !important;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              height: 2px;
              width: 60%;
              left: .9rem;
              bottom: -7px;
              background-color: $secondary-color;
            }
          }
        }
      }

      .read-more {
        flex-basis: 100%;
        margin: 0 !important;
        text-align: right;
        display: block;
        width: 100%;
      }
    }

    .popular-blogs {
      order: 2;
      margin: 2rem 0 0;
      border-bottom: 4px solid $light-grey;

      .popular-blog {
        border: 1px solid $grey;
        padding: .7rem .5rem;
        box-shadow: 12px 3px 10px rgba(60, 53, 51, 0.1), 12px 3px 10px rgba(60, 53, 51, 0.1);
        margin-bottom: 2rem;
        display: flex;

        .image {
          flex-basis: 40%;
        }

        .title {
          padding: 0 0 0 .9rem;
          color: $black;
          flex-basis: 60%;
          font-size: 1rem !important;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 60%;
            left: .9rem;
            bottom: -7px;
            background-color: $secondary-color;
          }
        }
      }
    }

    .comments {
      padding: 2rem 0;
      margin: 0;
      border-bottom: 4px solid $light-grey;
      order: 1;
    }

    .add-new-comment {
      order: 3;
      padding: 2rem 0 0;
    }
  }

  @include for-small-devices {
    .blog-sidebar {
      margin-top: 2rem;

      .related-posts {
        display: flex;
        margin: 0 -15px;

        & > .post-item {
          flex-basis: calc(100% / 3);
        }
      }

      .related-posts-container {

        .post-item {
          flex-wrap: nowrap;
          flex-direction: column;
          padding: 0 1rem;

          .details {
            p {
              padding-left: 0;
              font-size: .9rem;
              line-height: 1.2;
            }
          }

          .read-more {
            display: block;
            text-align: right;
          }
        }
      }
    }

    .blog-details {

      .blog-basic-details {
        margin-bottom: 1rem;
      }

      .blog-content {

        .blog-heading {
          margin-bottom: .5rem;
          font-size: 1.2rem;
          padding-bottom: .5rem;
        }

        .blog-additional-info {
          flex-direction: column;
        }
      }
    }

    .popular-blogs {
      order: 2;
    }

    .comments {
      order: 1;
    }

    .add-new-comment {
      order: 3;
    }
  }

  @include for-medium-devices {
    .blog-sidebar {
      .related-posts-container {
        .post-item {
          .read-more {
            display: block;
            text-align: right;
            width: 100%;
          }
        }
      }
    }

    .blog-details {
      .blog-content {

        .blog-additional-info {
          flex-direction: column;

          .share {
            .share-icons {

              i {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}
