@import "_components/variables";
@import "_components/components";
@import "_components/mixins";
@import "_components/home";
@import "_components/events";
@import "_components/event-details";
@import "_components/schools";
@import "_components/blog-details";
@import "_components/blogs";
@import "_components/school-compare";
@import "_components/school-details";
@import "_components/about-us";
@import "_components/modals";
@import "_components/bde-form";
@import "_components/school-form";
@import "_components/student-form";
@import "_components/nsi";
@import "_components/gallery";
@import "_components/gallery-inner";
@import "_components/faqs";
@import "_components/notifications";
@import "_components/notification-details";

* {
  font-family: Arial, sans-serif;
}

body {
  background-color: $white;
  color: $font-color;
  margin: 0;
  padding: 0;
}

header:not(#home) {
  @extend header;
  background: url("../img/events-header.png") no-repeat 100%;
  background-size: cover;
  position: relative;
  height: 85vh;
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .align-to-top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .navigation-bar {
    border: 0;

    .fixed {
      position: fixed;
      z-index: 999;
    }

    .nav-item {
      .nav-link {
        color: $white;
      }
    }
  }

  .navbar-logo {
    position: absolute;
    top: .5rem;
    left: 2rem;
    width: 100px;
    height: 100px;

    &.active {
      z-index: -1;
    }

    img {
      width: 100%;
    }
  }

  .top-bar {
    background-color: transparent;

    select {
      color: $font-color;
    }
  }

  .header-container {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    height: 100%;
    color: $white;
    z-index: auto;

    .custom-breadcrumb {
      margin-left: 8rem;
    }

    h1, .heading {
      font-size: 4rem;
      font-weight: bolder;
    }

    .breadcrumb-item {
      display: inline-block;

      a {
        color: $white;
      }

      &.active {
        color: $secondary-color;
      }

      &:first-of-type {
        margin-left: 4rem;
        color: $secondary-color;

        a {
          color: $secondary-color;
        }
      }

      &:not(:first-of-type) {
        &::before {
          content: "\f101";
          font-family: "Font Awesome 5 Free";
          font-weight: bold;
          color: $white;
        }
      }
    }
  }
}

footer {
  position: relative;
}

.floating-container-triggerer {
  display: none;
}

.floating-contact {
  display: flex !important;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  width: 60%;
  top: 60px;
  //position: absolute;
  //top: -40%;
  left: 20%;
  right: 20%;
  border-radius: 50px;
  background-color: $primary-color-shade;
  color: $white;
  padding: 0 2rem;
  z-index: 100;
  -webkit-box-shadow: 8px 10px 20px 6px transparentize($pure-black, .5);
  -moz-box-shadow: 8px 10px 20px 6px transparentize($pure-black, .5);
  box-shadow: 8px 10px 20px 6px transparentize($pure-black, .5);

  .vertical-line {
    width: 1px;
    background-color: $secondary-color;
    float: left;
    height: 75px;
    margin-top: 53px;
  }

  .vertical-line-middle {
    width: 1px;
    background-color: $secondary-color;
    float: left;
    height: 100px;
    margin-top: 40px;
    margin-left: 2px;
  }

  .vertical-line-last {
    width: 1px;
    background-color: $secondary-color;
    float: left;
    height: 75px;
    margin-top: 53px;
    margin-left: 2px;
  }

  .contact-item {
    width: calc(100% / 3);
    padding: 1rem .2rem;
    text-align: center;
    color: $secondary-color;

    &:last-child {
      border-right: 0;
    }

    .heading-container {

      i {
        font-size: 1.7rem;
        padding: 1rem;
      }

      h3 {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }

    .description {
      font-size: .8rem;
      color: $white;
    }
  }
}

#footer {
  //margin-top: 10rem;
  padding-top: 6rem;
  padding-bottom: .8rem;
  background-color: $footer-color;
  color: $white;

  .container-fluid {
    padding: 0 3rem !important;
  }

  * {
    color: $white;
    font-size: .8rem;
  }

  a {
    text-decoration: none;
  }

  .main-heading-style {
    text-transform: none;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    &::before {
      height: 2px;
      width: 40%;
    }
  }

  .footer-information {

    p {
      font-size: .8rem;
    }
  }

  .footer-quick-links {

    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        flex-basis: 50%;
      }
    }

    i {
      color: $secondary-color;
    }

    a {
      font-size: .8rem;
    }
  }

  .newsletter-container {

    .newsletter-form {
      width: 60%;
      position: relative;

      .newsletter-input {
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        border-radius: 1rem;
        height: initial;
        padding: .2rem .5rem;
        color: $pure-black;
      }

      button, input[type=submit] {
        position: absolute;
        right: 0;
        top: 0;
        padding: .2rem .7rem;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 1rem;

        i {
          font-size: 1rem;
        }
      }
    }

    i {
      color: inherit;
    }
  }

  @include for-medium-devices {
    .form-group {
      width: calc(100% - 40px);
    }
  }
}

.copyright-footer {
  background-color: $primary-color-shade;
  color: $white;

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $light-black;
    font-size: .7rem;
    padding: .7rem 0;

    p {
      margin: 0;

      a {
        color: $white;
      }
    }
  }
}

@include for-extra-small-devices {

  header:not(#home) {
    height: 70vh;
    .top-bar {
      padding: .5rem;
      flex-direction: column-reverse;
      align-items: flex-end;
      //align-items: flex-start;
      //justify-content: flex-end;

      .links {
        padding: 0 0 0 .5rem;
        margin-bottom: .7rem;
      }
    }

    .header-container {
      .custom-breadcrumb {
        margin: 0;
      }

      h1 {
        font-size: 3rem;
      }

      .breadcrumb-item {
        &:first-of-type {
          margin-left: 3rem;
        }
      }
    }
  }

  .floating-container-triggerer {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 1rem 1rem;
    position: relative;
    background: #001a39;
    color: #fdc800;
    font-weight: bold;
    width: 70%;
    margin: 2rem auto 0;
    border-radius: 20px;
    top: 20px;
    z-index: 111;

    p {
      line-height: 1;
      font-size: 1.4rem;
      margin: 0;
      padding-right: .5rem;
    }

    i {
      line-height: .7;
    }
  }

  .floating-contact {
    position: relative;
    flex-direction: column;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    top: initial;
    left: initial;
    right: initial;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 1.5rem 0 1rem;
    border-bottom: none;

    &.collapse:not(.show) {
      display: none !important;
    }

    .contact-item {
      width: 60%;
      margin: 0 auto;
      padding-top: 0;
      border: 0;

      p {
        margin: 0;
      }

      .heading-container {
        i {
          font-size: 1.3rem;
          padding: .3rem;
        }
      }
    }
  }

  .contact-container {
    border: 0 !important;
    padding: 0 !important;
  }
  .vertical-line {
    display: none;
  }
  .vertical-line-middle {
    display: none;
  }
  .vertical-line-last {
    display: none;
  }

  #footer {
    margin-top: 0;
    padding: 2rem 0;

    .main-heading-style {
      margin-bottom: .6rem;
    }

    .form-group {
      margin: 0;
    }

    .form-inline {
      text-align: center;
      justify-content: center;
    }

    .newsletter-container {
      margin-bottom: 1rem;

      .newsletter-form {
        width: 100%;
      }
    }

    .links-container {
      margin: 0;
    }
  }
}

@include for-small-devices {

  .floating-contact {
    top: 60px;
    width: 80%;
    left: 10%;

    .contact-item {
      .heading-container {
        i {
          padding: .7rem;
        }

        i, h3 {
          font-size: 1.2rem;
        }
      }

      .description {
        -ms-word-break: break-all;
        word-break: break-all;
      }
    }

    .vertical-line {
      //display: none;
    }

    .vertical-line-middle {
      //display: none;
    }

    .vertical-line-last {
      //display: none;
    }
  }

  #footer {
    margin-top: 0;
    padding-top: 6rem;

    .main-heading-style {
      font-size: 1.1rem;
      margin-bottom: .8rem;
    }

    .form-group {
      margin: 0;
    }

    .newsletter-container {
      margin-bottom: 1rem;
    }

    .social-links .links-container li {
      flex-basis: 20%;
    }
  }

  .copyright-footer {
    .content {
      justify-content: center;
    }
  }
}

@include for-medium-devices {
  .floating-contact {
    width: 80%;
    left: 10%;
  }
  #footer {
    .footer-information, .footer-quick-links, .newsletter, .social-links {
      overflow: hidden;
    }

    .newsletter-container {

      .newsletter-form {
        width: 100%;
      }
    }

    .social-links {
      .links-container {
        a {
          margin: 0 .5rem 1rem;
        }
      }
    }
  }
}
