@mixin border-side($side: left, $color: $grey, $size: 1px, $type: solid) {
  border-#{$side}: $size $type $color;
}

@mixin margin-side($side: left, $size:1rem) {
  margin-#{$side}: $size;
}

@mixin for-extra-small-devices {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin for-small-devices {
  @media (min-width: 576px) and (max-width: 767.98px) {
    @content;
  }
}

@mixin for-medium-devices {
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin for-large-devices {
  @media (min-width: 992px) and (max-width: 1199.98px) {
    @content;
  }
}

@mixin for-xl-devices {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-phones {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin for-tablets {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-desktops {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-large-desktops-devices {
  @media (min-width: 1200px) {
    @content;
  }
}
