$primary-color: #002147;
$primary-color-shade: #001a39;
$footer-color: #1d2331;
$secondary-color: #fdc800;

$white: #fff;
$black: #222;
$pure-black: #000;
$light-black: #666;

$font-color: #454851;
$heading-color: #1d2330;
$grey: #bbb;
$light-grey: #e7e7e7;

$light-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1);
$normal-shadow: 2px 2px 7px rgba(60, 53, 51, 0.4);
$square-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1), -2px -2px 3px rgba(60, 53, 51, 0.1);
