#notification-page {
  .heading {
    @extend .main-heading-style;
    font-size: 2rem;
    margin-bottom: 2rem;

    &::before {
      width: 150px;
      height: 3px;
    }
  }

  .notification-container {
    width: 100%;
    padding-top: 2rem;

    .notification-item {
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      .image {
        flex-basis: 25%;

        img {
          border-radius: 0;
          width: 100%;
          height: auto;
        }
      }

      .notification-details {
        flex-basis: 70%;
        padding: .5rem 0;

        h6 {
          margin: 0 0 .7rem;
          color: #222;
          line-height: 1;
          font-size: 1.4rem;
        }

        .details {
          font-size: .9rem;
        }

        .post-date {
          font-size: .75rem;
          margin: 0 0 0.3rem 0;

          p {
            margin: 0;
            padding: 0 0 0.2rem;
          }

          .divider {
            width: 30%;
            height: 2px;
            background-color: #fdc800;
          }
        }

        .details-button {
          text-align: right;

          .read-more {
            text-align: right;
            font-size: .9rem;
            font-style: italic;
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
  }

  @include for-extra-small-devices {
    .notification-container {
      width: 100%;
      padding: 0;

      .notification-item {
        flex-direction: column;
        margin: 0;
        border-bottom: 4px solid $light-grey;
        padding: 2rem 0;

        .image {
          flex-basis: 35%;
          margin-bottom: 1rem;
        }

        .notification-details {
          flex-basis: 62%;

          h6 {
            margin: 0;
            font-weight: bold;
            font-size: 1.2rem;
          }

          .post-date {
            .divider {
              width: 60%;
            }
          }

          .details {
            font-size: .8rem;
            line-height: 1.2;
            margin-bottom: .5rem;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  @include for-small-devices {
    .notification-container {
      width: 100%;
      padding: 0;

      .notification-item {
        margin: 0;
        border-bottom: 4px solid $light-grey;
        padding: 2rem 0;

        .image {
          flex-basis: 35%;
        }

        .notification-details {
          flex-basis: 62%;
          padding: 0;

          h6 {
            margin: 0;
            font-weight: bold;
            font-size: .9rem;
          }

          .post-date {
            .divider {
              width: 60%;
            }
          }

          .details {
            font-size: .8rem;
            line-height: 1.2;
            margin-bottom: .5rem;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  @include for-medium-devices {
    .notification-container {
      width: 70%;

      .notification-item {

        .notification-details {
          padding: 0;

          h6 {
            margin: 0 0 0.1rem;
            font-size: 1.2rem;
          }

          .post-date {
            margin: 0 0 0.1rem;

            p {
              padding: 0;
            }
          }

          .details {
            font-size: .8rem;
            line-height: 1.3;
          }
        }
      }
    }
  }
}
