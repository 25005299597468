.event-content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;

  .event-image {
    img {
      width: 100%;
    }
  }

  .content {
    //padding-left: 2rem;
  }

  .time-details {
    @extend .time-details;
    border: 0;
    align-items: flex-start;
    position: relative;
    margin-bottom: 2rem;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 85%;
      height: 2px;
      background-color: $secondary-color;
    }

    .time {
      margin-top: -13px;
      //margin-left: 1rem;
    }
  }

  .main-heading-style {
    font-size: 1.4rem !important;
    text-transform: none;
    color: $primary-color;
    text-align: justify;
  }

  .details {
    font-size: 1rem;
    color: $light-black;

    * {
      color: $black;
    }
  }

  .event-share {
    padding: 1rem 0;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    display: flex;
    align-items: center;
    justify-content: space-between;

    * {
      line-height: 1.2;
    }

    p {
      color: $black;
      margin: 0;
      text-transform: uppercase;
      font-weight: bold;
      flex-basis: 60%;
    }

    .links {
      flex-basis: 40%;
      display: flex;
      justify-content: space-between;
    }

    i {
      font-weight: normal;
      color: $black;
    }
  }

  .address {
    color: $primary-color;
    font-size: 1rem;
    font-weight: 600;

    &::before {
      content: "\f3c5";
      color: $secondary-color;
      font-family: "Font Awesome 5 Free";
    }
  }
}

.event-extra-details {
  margin-top: 2rem;
  margin-bottom: 2rem;

  #map {
    width: 100%;
    height: 200px;

    iframe {
      height: 200px;
      width: 100%;
    }
  }
}

@include for-extra-small-devices {
  .event-content {

    .content {
      padding: 0;
      overflow: hidden;
    }

    .time-details {
      padding: 0;
      align-items: flex-start;
      margin-bottom: 2rem;

      .date {
        font-size: 2rem;
      }

      .month {
        font-size: 1rem;
      }

      .time {
        font-size: .8rem;
        margin-left: 0;
      }
    }

    .details {
      font-size: .9rem;
      line-height: 1.2;
    }

    .address {
      text-align: justify;
      padding: 2rem 0 0;
    }

    .main-heading-style {
      font-size: 1.5rem;
    }
  }

  .event-extra-details {
    flex-direction: column-reverse;

    #map {
      margin: 0 0 2rem 0;
      padding: 0;
    }

    .join-form {

    }
  }
}

@include for-small-devices {
  .event-content {
    .content {
      padding: 0;
    }

    .time-details {
      padding: 0;
      align-items: flex-start;
      margin-bottom: 2rem;

      .date {
        font-size: 2rem;
      }

      .month {
        font-size: 1rem;
      }

      .time {
        font-size: .8rem;
      }
    }

    .main-heading-style {
      font-size: 1.5rem;

      &::before {
        display: none;
      }
    }

    .address {
      margin-top: 2rem;
    }
  }

  .event-extra-details {
    flex-direction: column-reverse;

    #map {
      margin: 0 0 2rem;
      padding: 0;
    }
  }
}

@include for-xl-devices {
  .event-content {

    .event-share {
      margin-top: 2rem;
    }

    .address {
      margin-top: 2rem;
    }
  }
}
