$first-bg-color: #9cc93a;
$first-color: $white;
$second-bg-color: $secondary-color;
$second-color: $black;
$third-bg-color: #f4671f;
$third-color: $white;

.comparison-table.school-compare {
  width: 100%;

  .border-bottom {
    @include border-side(bottom, darken($grey, 20%));
  }

  .summary-row {

    .summary-item {
      padding: 1rem 0 0 !important;
      @include border-side(bottom, $grey !important);

      .summary-title {
        font-size: 1.2rem !important;
        font-weight: bold;
      }
    }

    .comparison-item {
      border-bottom: 1px solid lighten($grey, 15%);
    }
  }

  .image-row {
    .comparison-item {
      padding: 3rem 1rem 0 !important;
      border-bottom: 0 !important;
    }
  }

  .position-row {
    .comparison-item {
      padding: 0 !important;
    }

    .position-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .shape {
        width: 20px;
        height: 20px;

        &.first {
          background-color: $first-bg-color;
        }

        &.second {
          background-color: $second-bg-color;
        }

        &.third {
          background-color: $third-bg-color;
        }
      }

      .text {
        margin-top: .2rem;
        font-size: 1rem;
        color: $black;
      }
    }
  }

  .ranking-list {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
  }

  .description {
    padding: 0 2rem !important;
  }

  .comparison-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: stretch;

    &.position-row, &.name-row {
      .comparison-item:first-of-type {
        border-bottom: 0;
      }
    }

    .comparison-item {
      flex-basis: calc(85% / 3);
      text-align: center;
      @include border-side(right, lighten($grey, 15%));
      font-size: .8rem;
      color: darken($light-black, 10%);
      display: flex;
      justify-content: center;
      padding: 1rem 0;

      &:first-of-type {
        flex-basis: 15%;
        @include border-side(bottom, lighten($grey, 20%));
        padding-right: 1rem;
        color: $pure-black;
        font-size: .9rem;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-weight: bold;
        }
      }

      &:last-of-type {
        border-right: none;
      }

      & > * {
        width: 100%;
      }

      h6 {
        color: $pure-black;
      }

      .availability-image {
        width: 10%;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }

      strong {
        font-size: .9rem;
        color: $pure-black;
      }

      * {
        margin: 0;
        padding: 0;
      }

      .school-image {
        width: 100%;
        padding: 3rem 2rem 1rem;

        img {
          width: 100%;
          border-radius: 15px;
          box-shadow: $light-shadow;
        }
      }

      .caption {
        text-align: center;
        color: $footer-color;
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        //position: relative;
        margin: 0 1rem 1rem;
        @include border-side(bottom, 3px, $secondary-color);

        /*&::after {
          position: absolute;
          content: "";
          bottom: 0;
          height: 3px;
          left: 20%;
          right: 20%;
          background-color: $secondary-color;
        }*/
      }
    }
  }

  .details-with-position-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .badge {
      padding: .4rem 1rem;
      border-radius: 20px;
      width: calc(27px + 2rem);
      text-align: center;
      font-size: .9rem;
      margin-bottom: .5rem;

      &.first {
        background-color: $first-bg-color;
        color: $first-color;
      }

      &.second {
        background-color: $second-bg-color;
        color: $second-color;
      }

      &.third {
        background-color: $third-bg-color;
        color: $third-color;
      }

    }
  }

  @include for-extra-small-devices {

    .comparison-header {
      border-bottom: 1px solid $grey;
      background-color: $white;
      transition: all 300ms ease-in-out;

      &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
      }
    }

    .image-row {
      .comparison-item {
        padding: 1rem .5rem !important;

        &:first-of-type {
          display: none;
        }
      }
    }

    .name-row {
      .comparison-item {
        padding: 0 0 .2rem !important;

        &:first-of-type {
          display: none;
        }

        .caption {
          margin: 0;
          font-size: .8rem;
          padding-bottom: .5rem;
        }
      }
    }

    .position-row {
      .position-details {
        padding: 1rem 0;
      }
    }

    .summary-row {
      & > :not(.summary-item) {
        display: none;
      }

      .summary-item {
        flex-basis: 100%;
        padding: 1rem .5rem !important;
        background-color: $light-grey !important;

        .summary-title {
          font-size: 1.5rem !important;
        }
      }
    }

    .ranking-list {
      justify-content: center;
      flex-wrap: wrap;

      & > li, & > div, & > .ranking-item {
        margin-bottom: 1rem !important;
        flex-basis: 100%;

        &:last-of-type {
          margin: 0 !important;
        }
      }
    }

    .comparison-row {
      flex-direction: row;
      flex-wrap: wrap;

      .comparison-item {
        flex-basis: calc(100% / 3);
        padding: 1rem .5rem;

        &:first-of-type {
          font-size: 1.1rem;
          flex-basis: 100%;
          border-right: 0;
        }

        .heading {
          width: initial;
          background-color: $secondary-color;
          padding: .4rem 1rem;
          border-radius: 15px;
        }

        .school-image {
          padding: 0;
        }

        .availability-image {
          width: 25%;
        }
      }
    }

    .description {
      padding: 0 !important;
    }
  }

  @include for-small-devices {

    .comparison-header {
      border-bottom: 1px solid $grey;
      background-color: $white;
      transition: all 300ms ease-in-out;

      &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
      }
    }

    .image-row {
      .comparison-item {
        padding: 1rem .5rem !important;

        &:first-of-type {
          display: none;
        }
      }
    }

    .name-row {
      .comparison-item {
        padding: 0 0 .2rem !important;

        &:first-of-type {
          display: none;
        }

        .caption {
          margin: 0;
          font-size: .8rem;
        }
      }
    }

    .position-row {
      .position-details {
        padding: 1rem 0;
      }
    }

    .summary-row {
      & > :not(.summary-item) {
        display: none;
      }

      .summary-item {
        flex-basis: 100%;
        padding: 1rem .5rem !important;
        background-color: $light-grey !important;

        .summary-title {
          font-size: 1.5rem !important;
        }
      }
    }

    .ranking-list {
      justify-content: center;
      flex-wrap: wrap;

      & > li, & > div, & > .ranking-item {
        margin-bottom: 1rem !important;
        flex-basis: 100%;

        &:last-of-type {
          margin: 0 !important;
        }
      }
    }

    .comparison-row {
      flex-direction: row;
      flex-wrap: wrap;

      .comparison-item {
        flex-basis: calc(100% / 3);
        padding: 1rem .5rem;

        &:first-of-type {
          font-size: 1.1rem;
          flex-basis: 100%;
          border-right: 0;
        }

        .heading {
          width: initial;
          font-size: 1.5rem;
          background-color: $secondary-color;
          padding: .4rem 1rem;
          border-radius: 15px;
        }

        .school-image {
          padding: 0;
        }

        .availability-image {
          width: 25%;
        }
      }
    }

    .description {
      padding: 0 !important;
    }

    /*.comparison-row {
      flex-direction: column;

      .comparison-item {

        &:first-of-type {
          font-size: 1.1rem;
          //background-color: $grey;
        }
      }
    }*/
  }

  @include for-medium-devices {

    .comparison-row {
      .comparison-item {
        .school-image {
          padding: 0;
        }
      }
    }

    .school-image {
      .caption {
        font-size: 1rem;
      }
    }
  }
}
