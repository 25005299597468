#about-page {
  .content {
    overflow: hidden;

    .heading {
      @extend .main-heading-style;
      color: $font-color;
      font-size: 2.2rem;

      &::before {
        left: 105%;
      }
    }

    .alternate-heading {
      @extend .main-heading-style;
      color: $font-color;
      font-size: 2.2rem;
      text-align: center;
      width: 100%;
      margin-bottom: 2.5rem;

      &::before {
        left: 33.3%;
        width: 33.3%;
        bottom: -10px;
      }
    }

    .description {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  .what-we-do {
    margin-top: 3rem;
  }

  .about-image {
    box-shadow: 14px 13px 20px rgba(0, 0, 0, 0.1), -14px -13px 20px rgba(0, 0, 0, .1);

    img {
      border: 10px white solid;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
    }
  }

  .content-item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    margin-top: 4rem;

    & > * {
      flex-basis: 100%;
    }

    .content-image {
      text-align: center;
      width: 33.3%;
      margin: 0 auto;
    }

    .heading {
      font-weight: bold;
      margin-bottom: 2rem;
    }

    .description {
      font-size: .8rem;
    }
  }

  @include for-extra-small-devices {
    padding: 2rem 0;

    .about-image {
      margin-top: 3rem;
    }

    .description {
      font-size: .9rem;
    }
  }

  @include for-small-devices {
    padding: 2rem 0;

    .about-image {
      margin-top: 3rem;
    }
  }
}
