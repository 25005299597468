#school-details-page {
  .slick-list {
    overflow: hidden !important;
    padding: 0;
  }

  .heading {
    @extend .main-heading-style;
    color: $primary-color;
    position: relative;
    text-transform: none;

    &::before {
      width: 100px;
      bottom: 10px !important;
    }
  }

  .school-card {
    box-shadow: 2px 2px 3px rgba(60, 53, 51, 0.1), -2px -2px 3px rgba(60, 53, 51, 0.1);
    padding: 1.2rem;
    margin: 1rem 0;
    overflow: hidden;

    .card-title {
      @extend .heading;
      font-size: 2rem;
      font-weight: bold;

      &::before {
        bottom: 0;
      }
    }

    .card-content {
      padding: 0 .7rem;
      font-size: 1rem;

      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
          display: flex;
          width: 100%;
          font-size: 1.1rem;
          margin-bottom: .4rem;

          .title {
            flex-basis: 35% !important;
          }

          .title-response {
            flex-basis: 65%;
          }
        }
      }

      .title {
        font-weight: bold;
      }

      .title-response {
      }
    }

    &.no-shadow {
      box-shadow: none;
    }
  }

  .slick-dots {
    bottom: 25px;

    li button {
      &::before {
        font-size: 10px;
        color: $white;
      }
    }
  }

  .school-details-header {

    .image-carousel {
      margin-top: -220px;
      z-index: 100;
      box-shadow: $light-shadow;

      img {
        width: 100%;
      }
    }

    .school-details {
      .headings {
        margin-bottom: 1rem;
      }

      .school-name {
        color: $primary-color;
        font-weight: bold;
      }

      .school-location {
        color: $primary-color;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -2px;
          bottom: -5px;
          width: 100px;
          height: 4px;
          background-color: $secondary-color;
        }
      }

      .buttons-container {
        width: 80%;
        margin-top: 3rem;
        display: flex;
        justify-content: space-around;

        .btn-round-secondary {
          font-size: .8rem;
          font-weight: bold;
          padding: .2rem .3rem;
          letter-spacing: 1px;
        }
      }
    }
  }

  .school-details-content {
    .school-info {
      width: 93%;

      .title {
        color: $primary-color;
      }

      .title-response {
        color: $black;
      }
    }

    .school-ranking {
      width: 80%;

      .title {
        flex-basis: 50% !important;
      }

      .star-rating {
        font-size: .8rem;
      }
    }

    .school-rating {
      @extend .school-ranking;
    }

    .school-features {
      .feature-row {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: .5rem;
        border-bottom: 1px solid lighten($grey, 20%);
        margin-top: 2rem;
        box-shadow: 2px 7px 10px -2px rgba(60, 53, 51, 0.1);

        .feature-item {
          flex-basis: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: .666rem;
          text-align: center;
          color: $pure-black;

          figure {
            margin-bottom: .5rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 40%;
            }
          }

          figcaption {
            font-size: 1.1rem;
            color: $primary-color;
            font-weight: bold;
            margin-top: .3rem;
          }

          .text {
            font-size: .666rem;
          }

          .star-rating-container {
            font-size: .6rem;

            i {
              font-size: .45rem;
            }
          }

          .more-link {
            background-color: $primary-color;
            font-size: .666rem;
            color: $white;
            padding: 0 .2rem;
            border-radius: 3px;
            margin-top: .3rem;
          }
        }
      }
    }

    .basic-params {

      .param-item {
        border: 1px solid lighten($grey, 5%);
        font-size: .6rem;
        color: $font-color;
        display: flex;

        .image {
          flex-basis: 40%;
          padding: .5rem;

          img {
            width: 100%;
          }
        }

        .content {
          flex-basis: 60%;
        }

        .param-title {
          font-size: 1.2rem;
          color: $primary-color;
          font-weight: bold;
        }

        .link-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          padding-right: .4rem;
        }
      }

      .more-link {
        background-color: $primary-color;
        font-size: .666rem;
        color: $white;
        padding: 0 .2rem;
        border-radius: 3px;
        margin: .3rem 0;
      }
    }
  }

  @include for-extra-small-devices {
    border-bottom: none;
    
    .school-card {
      .card-content {
        ul li {
          justify-content: space-between;
        }
      }
    }

    .school-details-header {
      .image-carousel {
        margin-top: 0;
        border: 5px solid $white;
        box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.3), -2px -2px 10px rgba(60, 53, 51, .3);
      }

      .school-details {
        padding-bottom: 2rem;
        border-bottom: 4px solid $light-grey;

        .school-name {
          margin: 0 0 1rem 0;
        }

        .school-location {
          margin-bottom: 2rem;
        }

        .buttons-container {
          width: 100%;

          .btn-round-secondary {
            padding: .3rem .5rem;
          }
        }
      }
    }

    .school-details-content {

      .school-card {

        .card-content {
          ul li {
            .title {
             flex-basis: 50%!important;
            }

            .title-response {
              flex-basis: 45%;
            }
          }
        }

      }

      .school-info-container {
        order: 2;
      }

      .school-ranking-container {
        order: 1;
      }

      .school-features-container {
        order: 3;
      }

      .school-basic-params-container {
        order: 4;
      }

      .school-info {
        width: 100%;
      }

      .school-ranking {
        width: 100%;
      }

      .basic-params {
        .param-item {
          flex-direction: column;
        }

        .content {
          padding: 0 .5rem;
        }

        .more-link {
          font-size: .8rem;
          padding: 0 .5rem;
          border-radius: 20px;
        }
      }
    }
  }

  @include for-small-devices {
    .school-details-header {
      .image-carousel {
        margin-top: 0;
        border: 5px solid $white;
        box-shadow: 2px 2px 10px rgba(60, 53, 51, 0.3), -2px -2px 10px rgba(60, 53, 51, .3);
      }

      .school-details {
        .school-name {
          margin: 0 0 1rem 0;
        }

        .school-location {
          margin-bottom: 2rem;
        }

        .buttons-container {
          width: 100%;

          .btn-round-secondary {
            padding: .3rem .5rem;
          }
        }
      }
    }

    .school-details-content {

      .school-info-container {
        order: 2;
      }

      .school-ranking-container {
        order: 1;
      }

      .school-features-container {
        order: 3;
      }

      .school-basic-params-container {
        order: 4;
      }

      .school-info {
        width: 100%;
      }

      .school-ranking {
        width: 100%;
      }

      .basic-params {
        .param-item {
          flex-direction: column;
        }

        .content {
          padding: 0 .5rem;
        }
      }
    }
  }

  @include for-medium-devices {
    padding: 2rem 0;

    .school-card {
      .card-content {
        ul {
          li {
            margin-bottom: .6rem;

            .title {
              flex-basis: 60%!important;
            }

            .title-response {
              flex-basis: 40%!important;
            }
          }
        }
      }
    }

    .school-info {
      .card-content {
        ul {
          li {
            margin-bottom: .6rem;

            .title {
              flex-basis: 50%!important;
            }

            .title-response {
              flex-basis: 50%!important;
            }
          }
        }
      }
    }

    .school-details-header {
      .image-carousel {
        margin-top: 0;
      }

      .school-details {
        .school-name {
          margin: 0 0 1rem 0;
        }

        .school-location {
          margin-bottom: 2rem;
        }

        .buttons-container {
          width: 100%;

          .btn-round-secondary {
            padding: .3rem .5rem;
          }
        }
      }
    }

    .school-details-content {
      .school-info {
        width: 100%;
      }

      .school-ranking {
        width: 100%;
      }

      .basic-params {
        .param-item {
          flex-direction: column;
        }

        .content {
          padding: 0 .5rem;
        }
      }
    }

    .star-rating {
      line-height: 2;
    }
  }

  .extra-info-container {
    .extra-info-image-slider {
      margin-bottom: 1.5rem;

      .image-item {
        border: 8px solid $white;
        box-shadow: $square-shadow;
      }

      img {
        //width: 100%;
      }
    }

    .extra-info-details {
      overflow: hidden;

      /* width */
      ::-webkit-scrollbar {
        width: 4px;
        margin-right: 1rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: lighten($grey, 10%);
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $secondary-color;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: darken($secondary-color, 20%);
      }

      .main-heading-style {
        text-transform: none;
        margin-bottom: 1rem;

        &::before {
          width: 200px;
        }
      }
    }

    .rating-details-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .rating-details {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-bottom: .3rem;

      .title {
        flex-basis: 40%;
      }

      .star-rating {
        flex-basis: 60%;
      }

      .title {
        text-align: left;
      }
    }
  }

  .extra-info-content {
    margin-bottom: 1.5rem;

    .main-heading-style, h3 {
      font-size: 1.2rem;
      margin-bottom: .2rem;

      &::before {
        width: 90px;
        bottom: 5px;
      }
    }

    p, .description {
      margin: 0;
    }
  }

  .extra-icon-container {
    height: 280px;
    overflow-y: auto;
  }

  .extra-icon-content {
    @extend .extra-info-content;
    display: flex;
    align-items: center;

    .image-icon {
      flex-basis: 16%;

      img {
        width: 100%;
      }
    }

    .icon-details {
      flex-basis: calc(84% - 1rem);
      margin-left: 1rem;
      display: flex;
      align-items: flex-end;

      .checkbox-image {
        flex-basis: 6%;

        img {
          width: 100%;
        }
      }

      h3 {
        flex-basis: calc(94% - 1rem);
        margin-left: 1rem;
        margin-bottom: 0;
      }
    }
  }
}
